import React, { useState } from "react";
import { useParams } from "react-router-dom";

import GroupTable from "./parts/groupTable";
import InviteOptions from "./parts/inviteOptions";

import { Helmet } from "react-helmet";

const ProjectGroup = (props) => {
  const [invitationStatus, setInvitationStatus] = useState(false);
  /** get project id */
  let { projectId, projectTitle } = useParams();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fikrimiz - Proje Grupları</title>
        <meta name="description" content="Sosyal Yaratıcılık Başlıyor" />
        <meta
          name="keywords"
          content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
        />
        <meta property="og:image" content="/assets/img/fikrimiz-org-3.png" />
      </Helmet>
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div className="row clearfix">
              <GroupTable
                projectId={projectId}
                projectTitle={projectTitle}
                setInvitationStatus={setInvitationStatus}
                invitationStatus={invitationStatus}
              />
              <div className="w-100 line d-block d-md-none"></div>
              <InviteOptions
                projectId={projectId}
                setInvitationStatus={setInvitationStatus}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectGroup;

import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import styles from "./Input.module.css";
import "react-datepicker/dist/react-datepicker.css";

function Input(props) {
  let inputElement = null;
  let validationMessage = "";

  const inputClasses = ["form-control not-dark", styles.inputElement];
  const inputBackgroundColor = "text-input-bg-color-gray";

  if (props.shouldValidate.required) {
    inputClasses.push(inputBackgroundColor);
  }

  if (props.invalid && props.shouldValidate && props.touched) {
    inputClasses.push(styles.invalid);
    validationMessage = props.validationError;
  }

  switch (props.elementType) {
    case "input":
      inputElement = (
        <input
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          disabled={props.disabled ? props.disabled : false}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          className={inputClasses.join(" ")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
    case "select":
      inputElement = (
        <select
          className={inputClasses.join(" ")}
          value={props.value}
          onChange={props.changed}
        >
          {props.elementConfig.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case "date":
      inputElement = (
        <ReactDatePicker
          selected={
            props.birdthDate
              ? props.birdthDate
              : props.setBirdthDate(new Date("2004", "01", "01"))
          }
          onChange={(date) => props.setBirdthDate(date)}
          className={inputClasses.join("text-input-bg-color-gray")}
          placeholderText={props.placeholderText}
          dateFormat="dd/MM/yyyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          scrollableYearDropdown
          required
        />
      );
      break;
    case "image":
      inputElement = (
        <div className="text-center">
          <img src="" height="100" id="imgPrev" className="mb-1"></img>
          <input
            id="imgUpload"
            className={inputClasses.join(" ")}
            {...props.elementConfig}
            value=""
            onChange={() => file4Base64()}
          />
        </div>
      );
      break;
    default:
      inputElement = (
        <input
          className={inputClasses.join("")}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
      break;
  }

  function file4Base64() {
    const preview = document.getElementById("imgPrev");
    const file = document.getElementById("imgUpload").files[0];

    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        const base64 = reader.result;
        preview.src = base64;
        props.setProfileImage(base64);
        props.setIsProfilFormValid(true);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  return (
    <div className={props.divClassName}>
      {props.label && <label className={styles.label}>{props.label}</label>}
      {inputElement}
      <div style={{ fontSize: 14 }} className={styles.validationError}>
        {validationMessage}
      </div>
    </div>
  );
}

export default Input;

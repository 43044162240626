// projectItem.js
import React, { useEffect } from "react";
import Moment from "moment";
import "moment/locale/tr";

import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

const BlogItem = (props) => {
  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  });

  /** Blog Detail Url */
  let url = "/blog/blog-detay/" + props.blog._id;
  return (
    <div className="col-lg-4 col-sm-6 mb-4">
      <Link to={url}>
        <div className="i-products">
          <div className="products-image">
            <a href={url}>
              <img
                src={
                  props.blog && props.blog.media ? props.blog.media.url : null
                }
                alt={props.blog ? props.blog.caption : null}
              />
              {/**<span className="badge">Gadgets</span>*/}
            </a>
          </div>
          <div className="products-desc">
            <h3 className="font-weight-bold">
              {" "}
              <a href={url}>{props.blog ? props.blog.caption : null}</a>
            </h3>
            <p>
              {" "}
              {props.blog && props.blog.description
                ? ReactHtmlParser(
                    props.blog.description
                      .replace("<p>", "")
                      .replace("</p>", "")
                      .substring(0, 100) + "..."
                  )
                : ""}
            </p>
            <div className="clear" />

            <div className="products-hoverlays">
              {/**  <span className="products-location"><i className="icon-map-marker1" /> Melbourne, Australia</span> */}

              <ul className="list-group-flush my-3 mb-0">
                <li className="list-group-item">
                  <strong>
                    {props.blog != null
                      ? Moment.utc(props.blog.createdDate).format(
                          "Do MMMM YYYY"
                        )
                      : null}{" "}
                  </strong>{" "}
                  tarihinde oluşturuldu
                </li>
                {/**   <li className="list-group-item"><strong>345</strong> Contributions</li>*/}
                {/**  <li className="list-group-item"><strong>20</strong> days remaining</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogItem;

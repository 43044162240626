// detailIdeaList.js
import React, { useState, Component, useEffect, useContext } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { confirmAlert } from "react-confirm-alert";

import IdeaEditForm from "../../forms/ideaEditForm";
import IdeaCommentForm from "../../forms/ideaCommentForm";
import * as constValues from "../../../utilities/constants";
import axios from "axios";

import AuthContext from "../../../store/authcontext";
import Loader from "react-loader-spinner";

const DetailIdeaList = (props) => {
  const [ideaObj, setIdeaObj] = useState(null);
  const [ideaId, setIdeaId] = useState(null);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const ctx = useContext(AuthContext);
  const [ideaList, setIdeaList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getIdeas = () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(constValues.API_URL + constValues.API_GET_PROJECT_DETAIL, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: props.projectId,
          })
          .then((response) => {
            const tempIdea = response.data.ResData.ideas;
            const sorted = [...tempIdea].sort(
              (a, b) => b.likeCount - a.likeCount
            );
            setIdeaList(sorted);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getIdeas();
  }, [ctx.isLoggedIn, props.ideaFormCrud, props.projectId]);

  const openCommentBox = (idea) => {
    setIdeaId(null);
    setShowCommentBox(true);
    setIdeaId(idea.id);
    setIdeaObj(idea);
  };

  return (
    <div className="card mt-4">
      <div className="card-header">
        <strong>Projedeki fikirler</strong>
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                Başlık
              </th>
              <th className="text-center">Bilgiler</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="3" className="text-center">
                  <Loader
                    style={{ textAlign: "center" }}
                    type="ThreeDots"
                    color="#31F4E8"
                    height={100}
                    width={100}
                  />
                </td>
              </tr>
            ) : ideaList && ideaList.length !== 0 ? (
              ideaList.map((idea, i) => (
                <tr key={i}>
                  <td colSpan="2">
                    <a
                      data-toggle="modal"
                      data-target="#ideaCommentedModal"
                      onClick={() => openCommentBox(idea)}
                      className="ideasLink"
                    >
                      {idea.ownIdea && (
                        <span>
                          <i className="icon-pencil2 mr-1"></i>
                        </span>
                      )}
                      {idea.caption}
                    </a>
                  </td>
                  <td
                    className="d-flex align-items-center justify-content-between"
                    style={{ minWidth: "110px" }}
                  >
                    <span className="d-flex align-items-center justify-content-around">
                      {idea.likeCount}
                      <img
                        src="/assets/img/toplamyildiz.png"
                        style={{ width: "25px", marginLeft: "2px" }}
                      ></img>
                    </span>
                    <span className="d-flex align-items-center justify-content-around">
                      {idea.commentCount}
                      <img
                        src="/assets/img/yorum.png"
                        style={{ width: "25px", marginLeft: "2px" }}
                      ></img>
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  Kayıt bulunamadı
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showCommentBox && (
        <IdeaCommentForm
          ideaList={ideaList}
          setIdeaList={setIdeaList}
          ideaId={ideaId}
          projectId={props.projectId}
          projectTitle={props.projectTitle}
          ideaObj={ideaObj}
          setIdeaFormCrud={props.setIdeaFormCrud}
          ideaFormCrud={props.ideaFormCrud}
          setShowCommentBox={setShowCommentBox}
        />
      )}
    </div>
  );
};
export default DetailIdeaList;

import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  API_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  SIGNUP_URL,
  VERIFY_PHONE_URL,
  FACEBOOK_APP_ID,
} from "../../utilities/constants";
import Token from "../../utilities/token";
import Input from "../UI/Input";
import AuthContext from "../../store/authcontext";
import ReactFacebookLogin from "react-facebook-login";
import IdeaForm from "../forms/ideaForm";
import Loader from "react-loader-spinner";

const Login = (props) => {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [isForgotPasswordForm, setIsForgotPasswordForm] = useState(false);
  const [isRegisterForm, setIsRegisterForm] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [loginFormValid, setLoginFormValid] = useState(false);
  const [registerFormValid, setRegisterFormValid] = useState(false);
  const [forgotPasswordFormValid, setForgotPasswordFormValid] = useState(false);
  const [verifyPhoneFormValid, setVerifyPhoneFormValid] = useState(false);
  const [birdthDate, setBirdthDate] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const authCtx = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(null);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [ideaModalStatus, setIdeaModalStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const setAgreement = () => {
    setAgreementChecked(!agreementChecked);
  };

  const [loginForm, setLoginForm] = useState({
    username: {
      elementType: "input",
      elementConfig: {
        type: "number",
        placeholder: "Telefon (5xxxxxxxxx)",
      },
      value: "",
      validation: {
        required: true,
        isNumeric: true,
        minLength: 10,
        maxLength: 10,
      },
      valid: false,
      touched: false,
      divClassName: "col-md-12",
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Şifre",
      },
      value: "",
      validation: {
        required: true,
        minLength: 4,
        maxLength: 20,
      },
      valid: false,
      touched: false,
      divClassName: "col-md-12 mt-4",
    },
  });
  const [registerForm, setRegisterForm] = useState({
    name: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Ad",
      },
      value: "",
      validation: {
        required: true,
        minLength: 2,
        maxLength: 15,
      },
      valid: false,
      touched: false,
      divClassName: "col-md-6 mt-4",
    },
    lastName: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Soyad",
      },
      value: "",
      validation: {
        required: true,
        minLength: 2,
        maxLength: 15,
      },
      valid: false,
      touched: false,
      divClassName: "col-md-6 mt-4",
    },
    gender: {
      elementType: "select",
      elementConfig: {
        type: "select",
        placeholder: "Cinsiyet",
        options: [
          { value: "1", displayValue: "Erkek" },
          { value: "2", displayValue: "Kadın" },
        ],
      },
      value: 1,
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
    },
    birthDate: {
      elementType: "date",
      elementConfig: {
        type: "text",
        placeholder: "Doğum Tarihi",
      },
      validation: {
        required: true,
      },
      value: birdthDate,
      valid: true,
      touched: true,
      divClassName: "col-md-6 mt-4",
      placeholderText: "Doğum Tarihi",
      //label: "Doğum Tarihi",
    },
    phoneNumber: {
      elementType: "input",
      elementConfig: {
        type: "number",
        placeholder: "Telefon (5xxxxxxxxx)",
      },
      value: "",
      validation: {
        required: true,
        isNumeric: true,
        minLength: 10,
        maxLength: 10,
      },
      valid: false,
      touched: false,
      divClassName: "col-md-6 mt-4",
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Şifre",
      },
      value: "",
      validation: {
        required: true,
        minLength: 4,
        maxLength: 30,
      },
      valid: false,
      touched: false,
      divClassName: "col-md-6 mt-4",
    },
    imageData: {
      elementType: "image",
      elementConfig: {
        type: "file",
        placeholder: "Profil Resmi",
        accept: "image/*",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: true,
      touched: true,
      divClassName: "col-md-12 mt-4",
      label: "Profil Resmi",
    },
  });
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    phoneNumber: {
      elementType: "input",
      elementConfig: {
        type: "number",
        placeholder: "Telefon (5xxxxxxxxx)",
      },
      value: "",
      validation: {
        required: true,
        minLength: 10,
        maxLength: 10,
      },
      valid: false,
      touched: false,
      divClassName: "col-md-12",
    },
  });
  const [verifyPhoneForm, setVerifyPhoneForm] = useState({
    verifyCode: {
      elementType: "input",
      elementConfig: {
        type: "number",
        placeholder: "Doğrulama Kodu",
      },
      value: "",
      validation: {
        required: true,
        minLength: 4,
        maxLength: 4,
      },
      valid: false,
      touched: false,
      divClassName: "col-md-12",
    },
  });
  const signupUrl = `${API_URL}${SIGNUP_URL}`;
  const loginUrl = `${API_URL}${LOGIN_URL}`;
  const forgotPasswordUrl = `${API_URL}${FORGOT_PASSWORD_URL}`;
  const verifyPhoneUrl = `${API_URL}${VERIFY_PHONE_URL}`;
  const [token, setToken] = useState(
    localStorage.getItem("REACT_TOKEN_ANONYMOUS")
  );

  useEffect(() => {
    if (isLoginForm) {
      authCtx.onLogout();
      Token().getToken();
      setToken(localStorage.getItem("REACT_TOKEN_ANONYMOUS"));
    }
  }, []);

  useEffect(() => {
    setRegisterForm((prevState) => ({
      ...prevState,
      birthDate: {
        ...prevState.birthDate,
        value: birdthDate,
      },
      imageData: {
        ...prevState.imageData,
        value: profileImage,
      },
    }));
  }, [birdthDate, profileImage]);

  let history = useHistory();

  const loginFormArray = [];
  for (const key in loginForm) {
    loginFormArray.push({
      id: key,
      config: loginForm[key],
    });
  }

  const registerFormArray = [];
  for (const key in registerForm) {
    registerFormArray.push({
      id: key,
      config: registerForm[key],
    });
  }

  const forgotPasswordFormArray = [];
  for (const key in forgotPasswordForm) {
    forgotPasswordFormArray.push({
      id: key,
      config: forgotPasswordForm[key],
    });
  }
  const verifyPhoneFormArray = [];
  for (const key in verifyPhoneForm) {
    verifyPhoneFormArray.push({
      id: key,
      config: verifyPhoneForm[key],
    });
  }

  const inputChangedHandler = (event, inputIdentifier) => {
    let updatedForm = isLoginForm
      ? { ...loginForm }
      : isForgotPasswordForm
      ? { ...forgotPasswordForm }
      : isRegisterForm
      ? { ...registerForm }
      : { ...verifyPhoneForm };

    const updatedFormElement = { ...updatedForm[inputIdentifier] };

    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation,
      updatedFormElement.elementConfig.placeholder
    );

    updatedFormElement.touched = true;
    updatedForm[inputIdentifier] = updatedFormElement;

    let formValid = true;

    for (const inputIdentifier in updatedForm) {
      formValid = updatedForm[inputIdentifier].valid && formValid;
    }

    if (isLoginForm) {
      setLoginForm(updatedForm);
      setLoginFormValid(formValid);
    } else if (isForgotPasswordForm) {
      setForgotPasswordForm(updatedForm);
      setForgotPasswordFormValid(formValid);
    } else if (isRegisterForm) {
      setRegisterForm(updatedForm);
      setRegisterFormValid(formValid);
    } else {
      setVerifyPhoneForm(updatedForm);
      setVerifyPhoneFormValid(formValid);
    }
  };

  const checkValidity = (value, rules, placeholder) => {
    let isValid = true;

    if (rules.required) {
      if (value.trim() === "") {
        setValidationError(`${placeholder} alanı boş olamaz.`);
        isValid = false;
      }
    }

    if (rules.isEmail) {
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (!pattern.test(value) && isValid) {
        setValidationError(
          `${placeholder} alanına geçersiz email adresi girdiniz. ${value} `
        );
        isValid = false;
      }
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      if (!pattern.test(value) && isValid) {
        setValidationError(
          ` ${placeholder} alanına sadece rakam girebilirsiniz. ${value}`
        );
        isValid = false;
      }
    }

    if (rules.minLength) {
      if (value.length < rules.minLength && isValid) {
        setValidationError(
          `${placeholder} alanı en az ${rules.minLength} karakter olmalıdır. ${value.length} karakter girdiniz.`
        );
        isValid = false;
      }
    }
    if (rules.maxLength) {
      if (value.length > rules.maxLength && isValid) {
        setValidationError(
          `${placeholder} alanı en fazla ${rules.maxLength} karakter olabilir. ${value.length} karakter girdiniz.`
        );
        isValid = false;
      }
    }

    return isValid;
  };

  const submitHandler = () => {
    setIsLoading(true);
    let formData = {};
    let url = "";
    if (isLoginForm) {
      for (const key in loginForm) {
        formData[key] = loginForm[key].value;
      }
      url = loginUrl;
    } else if (isForgotPasswordForm) {
      formData = forgotPasswordForm.phoneNumber.value;
      url = forgotPasswordUrl;
    } else if (isRegisterForm) {
      for (const key in registerForm) {
        formData[key] = registerForm[key].value;
      }
      url = signupUrl;
      // check agreement is checked
      if (!agreementChecked) {
        NotificationManager.info(
          "Lütfen kurallar metnini okuduğunuzu onaylayın"
        );
        return;
      }
    } else {
      formData = verifyPhoneForm.verifyCode.value;
      url = verifyPhoneUrl;
    }
    const body = {
      ReqToken: token,
      ReqData: formData,
    };

    axios
      .post(url, body)
      .then(function (response) {
        const res = response.data;
        setIsLoading(false);
        // sonucu handle et
        if (isLoginForm) {
          if (res.ResCode === 200) {
            setToken(res.ResData.token);
            if (res.ResData.verifyStatus === 1) {
              setIsLoginForm(false);
              setIsForgotPasswordForm(false);
              setIsRegisterForm(false);
              setUserInfo(res.ResData);
            } else {
              authCtx.onLogin(res.ResData);
              props.loginModal(false);
              if (
                localStorage.getItem("COMES_FROM_IDEA") != null &&
                localStorage.getItem("COMES_FROM_IDEA") === "Y"
              ) {
                props.setModalIdeaForm(true);
                setIdeaModalStatus(true);
              } else {
                history.push("/profile");
              }
            }
          }
        } else if (!isLoginForm && !isForgotPasswordForm && !isRegisterForm) {
          if (res.ResCode === 200) {
            authCtx.onLogin(userInfo);
            props.loginModal(false);
            if (
              localStorage.getItem("COMES_FROM_IDEA") != null &&
              localStorage.getItem("COMES_FROM_IDEA") === "Y"
            ) {
              props.setModalIdeaForm(true);
              setIdeaModalStatus(true);
            } else {
              history.push("/profile");
            }
          } else {
            setValidationError(res.ResMessage);
            setErrorMessage(res.ResMessage);
            setVerifyPhoneFormValid(false);
            setVerifyPhoneForm((prevState) => ({
              verifyCode: {
                ...prevState.verifyCode,
                value: "",
              },
            }));
          }
        } else if (!isForgotPasswordForm) {
          if (res.ResCode === 200) {
            setUserInfo(res.ResData);
            setIsLoginForm(false);
            setIsForgotPasswordForm(false);
            setIsRegisterForm(false);
            setToken(res.ResData.token);
            //localStorage.removeItem("REACT_TOKEN_ANONYMOUS");
            //localStorage.setItem("REACT_TOKEN_USER", res.ResData.token);
          } else {
            setErrorMessage(res.ResMessage);
            setValidationError(res.ResMessage);
            setRegisterFormValid(false);
          }
        }
        NotificationManager.info(res.ResMessage);
        setErrorMessage(res.ResMessage);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const responseFacebook = (response) => {
    // check if facebook response is null or not
    if (response.length > 0) {
      const name = response.first_name;
      const lastName = response.last_name;
      const email = response.email;
      const gender = response.gender;
      const birthDatefb = response.birthday;
      const picture = response.picture.data.url;

      setRegisterForm((prevState) => ({
        ...prevState,
        name: {
          ...prevState.name,
          value: name && name,
          valid: name && true,
        },
        lastName: {
          ...prevState.lastName,
          value: lastName && lastName,
          valid: lastName && true,
        },
        email: {
          ...prevState.email,
          value: email && email,
          valid: email && true,
        },
        gender: {
          ...prevState.gender,
          value: gender && gender === "male" ? 1 : 2,
        },
        birthDate: {
          ...prevState.birthDate,
          value: birthDatefb && birthDatefb,
        },
      }));
      setBirdthDate(birthDatefb && new Date(birthDatefb));
      picture && url2Base64(picture, (base64) => setProfileImage(base64));
    }
  };

  function url2Base64(url, callback) {
    const preview = document.getElementById("imgPrev");
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;

    img.onload = function () {
      let canvas = document.createElement("CANVAS");
      const ctx = canvas.getContext("2d");
      let base64 = "";
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      base64 = canvas.toDataURL();
      preview.src = base64;
      callback(base64);
      canvas = null;
    };
  }

  let form = isLoginForm ? (
    <React.Fragment>
      <form>
        <div className="form-row">
          {loginFormArray.map((item) => (
            <Input
              key={item.id}
              divClassName={item.config.divClassName}
              elementType={item.config.elementType}
              elementConfig={item.config.elementConfig}
              value={item.config.value}
              invalid={!item.config.valid}
              shouldValidate={item.config.validation}
              touched={item.config.touched}
              validationError={validationError}
              changed={(event) => inputChangedHandler(event, item.id)}
            />
          ))}
        </div>
        <div className="col-12 text-right mt-2">
          <button
            className="buttonLink text-dark font-weight-light text-smaller"
            onClick={() => {
              setIsLoginForm(false);
              setIsForgotPasswordForm(true);
            }}
            type="button"
          >
            Şifremi Unuttum?
          </button>
        </div>
      </form>
      <div className="col-12 mt-4">
        {isLoading ? (
          <Loader
            style={{ textAlign: "center" }}
            type="ThreeDots"
            color="#31F4E8"
            height={50}
            width={50}
          />
        ) : (
          <button
            className="button btn-block m-0"
            onClick={() => submitHandler()}
            disabled={loginFormValid ? "" : "disabled"}
            type="submit"
          >
            Giriş
          </button>
        )}
      </div>
    </React.Fragment>
  ) : isForgotPasswordForm ? (
    <React.Fragment>
      <form>
        {forgotPasswordFormArray.map((item) => (
          <Input
            key={item.id}
            divClassName={item.config.divClassName}
            elementType={item.config.elementType}
            elementConfig={item.config.elementConfig}
            value={item.config.value}
            invalid={!item.config.valid}
            shouldValidate={item.config.validation}
            touched={item.config.touched}
            validationError={validationError}
            changed={(event) => inputChangedHandler(event, item.id)}
          />
        ))}
      </form>
      <div className="col-12 mt-4">
        {isLoading ? (
          <Loader
            style={{ textAlign: "center" }}
            type="ThreeDots"
            color="#31F4E8"
            height={50}
            width={50}
          />
        ) : (
          <button
            className="button btn-block m-0"
            onClick={() => submitHandler()}
            disabled={forgotPasswordFormValid ? "" : "disabled"}
            type="submit"
          >
            Gönder
          </button>
        )}
      </div>
    </React.Fragment>
  ) : isRegisterForm ? (
    <React.Fragment>
      <form>
        {/**
                 <div className="text-center">
                 <ReactFacebookLogin
                 appId={FACEBOOK_APP_ID}
                 fields="first_name,last_name,email,birthday,gender,picture"
                 // scope="public_profile,email,user_gender,user_birthday"
                 callback={(response) => responseFacebook(response)}
                 textButton="Facebook Bilgilerimi Kullan"
                 />
                 </div>
                 */}
        <div className="form-row">
          {registerFormArray.map((item) => (
            <Input
              key={item.id}
              divClassName={item.config.divClassName}
              label={item.config.label}
              placeholderText={item.config.placeholderText}
              birdthDate={birdthDate}
              setBirdthDate={setBirdthDate}
              profileImage={profileImage}
              setProfileImage={setProfileImage}
              elementType={item.config.elementType}
              elementConfig={item.config.elementConfig}
              value={item.config.value}
              invalid={!item.config.valid}
              shouldValidate={item.config.validation}
              touched={item.config.touched}
              validationError={validationError}
              changed={(event) => inputChangedHandler(event, item.id)}
              linkText={item.config.linkText}
              link={item.config.link}
            />
          ))}
        </div>
        <div className="form-check text-center p-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck1"
            checked={agreementChecked}
            onChange={() => setAgreement()}
          />
          <label className="form-check-label" for="exampleCheck1">
            Üye olarak{" "}
            <a
              href="http://videa.com.tr/kurallar.html"
              target="_blank"
              rel="noreferrer"
            >
              Kullanıcı Sözleşmesini
            </a>{" "}
            okuduğunuzu kabul etmiş olursunuz
          </label>
        </div>
      </form>
      <div className="col-12 mt-4">
        {isLoading ? (
          <Loader
            style={{ textAlign: "center" }}
            type="ThreeDots"
            color="#31F4E8"
            height={50}
            width={50}
          />
        ) : (
          <button
            className="button btn-block m-0"
            onClick={() => submitHandler()}
            disabled={registerFormValid ? "" : "disabled"}
            type="submit"
          >
            Üye Ol
          </button>
        )}
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <form>
        {verifyPhoneFormArray.map((item) => (
          <Input
            key={item.id}
            divClassName={item.config.divClassName}
            elementType={item.config.elementType}
            elementConfig={item.config.elementConfig}
            value={item.config.value}
            invalid={!item.config.valid}
            shouldValidate={item.config.validation}
            touched={item.config.touched}
            validationError={validationError}
            changed={(event) => inputChangedHandler(event, item.id)}
          />
        ))}
      </form>
      <div className="col-12 mt-4">
        {isLoading ? (
          <Loader
            style={{ textAlign: "center" }}
            type="ThreeDots"
            color="#31F4E8"
            height={50}
            width={50}
          />
        ) : (
          <button
            className="button btn-block m-0"
            onClick={() => submitHandler()}
            disabled={verifyPhoneFormValid ? "" : "disabled"}
            type="submit"
          >
            Onayla
          </button>
        )}
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div
        className="mfp-bg mfp-no-margins mfp-fade mfp-ready"
        onClick={() => props.loginModal(false)}
      ></div>
      <div
        className="myModal"
        style={
          isLoginForm || isForgotPasswordForm || !isRegisterForm
            ? {}
            : { maxHeight: "90%", overflowY: "scroll", overflowX: "hidden" }
        }
      >
        <div
          className="card mx-auto"
          style={
            isLoginForm || isForgotPasswordForm || !isRegisterForm
              ? { maxWidth: "400px" }
              : { maxWidth: "800px" }
          }
        >
          <div className="card-header py-3 bg-transparent center">
            <h3 className="mb-0 font-weight-normal">
              {isLoginForm
                ? "Merhaba, Giriş Yapınız"
                : isForgotPasswordForm
                ? "Şifremi Unuttum"
                : isRegisterForm
                ? "Üye Ol"
                : "Numaranızı Doğrulayın"}
            </h3>
          </div>
          <div className="card-body mx-auto p-5">
            {errorMessage && (
              <h4 style={{ color: "red", textAlign: "center" }}>
                {errorMessage}
              </h4>
            )}
            {form}
          </div>
          <div className="card-footer py-4 center">
            <p className="mb-0">
              {isLoginForm ? " Hesabınız yok mu?" : "Zaten üye misiniz?"}
              <button
                style={{
                  outline: "none",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#1ABC9C",
                }}
                onClick={() => {
                  if (isLoginForm) {
                    setIsLoginForm(false);
                    setIsRegisterForm(true);
                  } else {
                    setIsLoginForm(true);
                  }
                  setIsForgotPasswordForm(false);
                }}
              >
                <u>{isLoginForm ? "Üye Ol" : "Giriş Yap"}</u>
              </button>
            </p>
          </div>
        </div>
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close2"
          onClick={() => props.loginModal(false)}
        >
          ×
        </button>
      </div>
    </React.Fragment>
  );
};

export default Login;

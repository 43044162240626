import React, { useEffect, useState, useContext } from "react";
import Loader from "react-loader-spinner";
import { NotificationManager } from "react-notifications";

/** api  */
import * as constValues from "../../../utilities/constants";
import axios from "axios";
import AuthContext from "../../../store/authcontext";

const Settings = () => {
  const [settingList, setSettingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const ctx = useContext(AuthContext);

  useEffect(() => {
    const getUserNotificationSettings = () => {
      setIsLoading(false);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(
            constValues.API_URL + constValues.GET_USER_NOTIFICATION_SETTINGS,
            {
              ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
              ReqData: {},
            }
          )
          .then((response) => {
            setSettingList(response.data.ResData);
            setIsLoading(true);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getUserNotificationSettings();
  }, [ctx.isLoggedIn]);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  });

  const handleChange = (e, number) => {
    let isChecked = e.target.checked;
    if (number === 1) {
      let set1 = { settingId: "1", isActive: isChecked };
      updateSettings(set1);
    } else if (number === 2) {
      let set2 = { settingId: "2", isActive: isChecked };
      updateSettings(set2);
    } else if (number === 3) {
      let set3 = { settingId: "3", isActive: isChecked };
      updateSettings(set3);
    } else if (number === 4) {
      let set4 = { settingId: "4", isActive: isChecked };
      updateSettings(set4);
    } else if (number === 5) {
      let set5 = { settingId: "5", isActive: isChecked };
      updateSettings(set5);
    }
  };

  // update settings
  const updateSettings = (obj) => {
    const userToken = localStorage.getItem("REACT_TOKEN_USER");
    const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
    if (userToken !== null || anonymousToken !== null) {
      axios
        .post(
          constValues.API_URL + constValues.UPDATE_USER_NOTIFICATION_SETTINGS,
          {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: obj,
          }
        )
        .then((response) => {
          NotificationManager.info(response.data.ResMessage, null, 3000);
          this.getUserNotificationSettings();
        })
        .catch((error) => console.error("Error", error));
    }
  };

  return (
    <div
      className="modal fade show"
      id="settingsModal"
      tabIndex={-1}
      aria-labelledby="settingsModal"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Bildirim Ayarları
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            {isLoading && settingList ? (
              <div className="modal-body bg-transparent m-0">
                <div className="row col-md-12 mb-4">
                  <div className="col-sm-12">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="defaultCheck1"
                        onChange={(e) => handleChange(e, 1)}
                        className="form-check-input"
                        defaultChecked={
                          isLoading && settingList
                            ? settingList[0].isActive
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Projeye Yorum Bildirim
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-4">
                  <div className="col-sm-12">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="defaultCheck2"
                        className="form-check-input"
                        onChange={(e) => handleChange(e, 2)}
                        defaultChecked={
                          isLoading && settingList
                            ? settingList[1].isActive
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck2"
                      >
                        Projeye Fikir Bildirim
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-4">
                  <div className="col-sm-12">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="defaultCheck3"
                        className="form-check-input"
                        onChange={(e) => handleChange(e, 3)}
                        defaultChecked={
                          isLoading && settingList
                            ? settingList[2].isActive
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck3"
                      >
                        Yeni proje bildirim
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-4">
                  <div className="col-sm-12">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="defaultCheck4"
                        className="form-check-input"
                        onChange={(e) => handleChange(e, 4)}
                        defaultChecked={
                          isLoading && settingList
                            ? settingList[3].isActive
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck4"
                      >
                        Güncel Blog Bildirim
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mb-4">
                  <div className="col-sm-12">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="defaultCheck5"
                        className="form-check-input"
                        onChange={(e) => handleChange(e, 5)}
                        defaultChecked={
                          isLoading && settingList
                            ? settingList[4].isActive
                            : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck5"
                      >
                        Liderler listesinde görünmek istiyorum
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loader
                style={{ textAlign: "center" }}
                type="ThreeDots"
                color="#31F4E8"
                height={100}
                width={100}
              />
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                İptal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Settings;

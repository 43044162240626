// Related Invites.js
import React, { useState, Component, useEffect, useContext } from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

/** api part */
import * as constValues from "../../../utilities/constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import AuthContext from "../../../store/authcontext";

const RelatedInvites = (props) => {
  const ctx = useContext(AuthContext);
  const [inviteList, setInviteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getRelatedInviteList = () => {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(
            constValues.API_URL + constValues.GET_PROJECT_GROUP_RELATED_INVITES,
            {
              ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
              ReqData: props.projectId,
            }
          )
          .then((response) => {
            setInviteList(response.data.ResData);
            setIsLoading(true);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getRelatedInviteList();
  }, [ctx.isLoggedIn]);

  const SetInvitationStatus = (codeNumber) => {
    switch (codeNumber) {
      case 2: // Accepted
        return "Kabul Edildi";
      case 3: // Waiting
        return "Bekleniyor";
      case 4: // Not Accepted
        return "Reddedildi";
      case 5: // Canceled
        return "İptal Edildi";
      case 0: // Send invitation
        return "Davet Gönder <i class='icon-envelope2 ml-1'></i>";
      default:
        // Send invitation
        return "Davet Gönder <i class='icon-envelope2 ml-1'></i>";
    }
  };

  const sendInvitation = (invitationStatus, userId) => {
    if (
      invitationStatus == 0 ||
      invitationStatus == 1 ||
      invitationStatus == null
    ) {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(constValues.API_URL + constValues.GET_PROJECT_SEND_INVITATION, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: {
              userInvitationId: userId,
              userFromType: constValues.INVITATION_TYPE_USER_ID,
              projectId: props.projectId,
            },
          })
          .then((response) => {
            NotificationManager.info(response.data.ResMessage);
            //alert(response.data.ResMessage);
            setIsLoading(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch((error) => console.error("Error", error));
      }
    } else {
      NotificationManager.info(
        "Sadece davet edilmeyen kullancılara davet gönderebilirsiniz"
      );
      //alert("Sadece davet edilmeyen kullancılara davet gönderebilirsiniz");
    }
  };

  return (
    <div
      id="relatedInvitesModal"
      className="modal fade bs-example-modal-lg show"
      tabIndex={-1}
      aria-labelledby="relatedInvitesModal"
      style={{ display: "none", paddingRight: "17px" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-body">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Favori Davet Listesi
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <ul className="list-group">
                    {isLoading && inviteList ? (
                      inviteList.map((inviteObj, i) => {
                        return (
                          <li className="list-group-item" key={i}>
                            {inviteObj.userName}
                            <span
                              className="badge badge-secondary float-right"
                              style={{ marginTop: "3px" }}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Projeye davet etmek istediğinize emin misiniz ?"
                                  )
                                )
                                  sendInvitation(
                                    inviteObj.invitationStatus,
                                    inviteObj.userId
                                  );
                              }}
                            >
                              {ReactHtmlParser(
                                SetInvitationStatus(inviteObj.invitationStatus)
                              )}
                            </span>
                          </li>
                        );
                      })
                    ) : (
                      <li className="list-group-item">Kayıt bulunamadı</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedInvites;

export default function validate(values) {
    let errors = {};
    /**Empty */

    if (!values.formSubject) {
        errors.formSubject = 'Konu alanı boş geçilmemelidir';
    }
    if (!values.formMessage) {
        errors.formMessage = 'Mesaj alanı boş geçilmemelidir';
    }

    return errors;
};
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // detect page is reloaded or not
  if (window.performance) {
    if (performance.navigation.type == 1) {
      //This page is reloaded
      document.body.classList.remove('primary-menu-open');
    } else {
      //This page is not reloaded
    }
  }

  useEffect(() => {
    document.body.classList.remove('primary-menu-open');
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
import React, { useEffect } from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate, pageNumber }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <nav>
      <ul className="pagination pagination-transparent pagination-rounded">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={number == pageNumber ? "page-item active" : "page-item"}
          >
            <a onClick={() => paginate(number)} className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;

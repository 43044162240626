// Content.js
import React, { useState, Component, useEffect, useContext } from "react";
import * as constValues from "../../utilities/constants";
import axios from "axios";
import AuthContext from "../../store/authcontext";
import Loader from "react-loader-spinner";
import Slider from "../home/parts/Slider";
import Content from "../home/parts/Content";

import { Helmet } from "react-helmet";

const Home = () => {
  const [pageContent, setPageContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const getAllContents = () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (
        (ctx.isLoggedIn && userToken !== null) ||
        (!ctx.isLoggedIn && anonymousToken !== null)
      ) {
        axios
          .post(
            constValues.API_URL + constValues.API_GET_SITEMANAGEMENT_VALUES,
            {
              ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
              ReqData: constValues.PAGE_GROUP_NAME_ANASAYFA,
            }
          )
          .then((response) => {
            setPageContent(response.data.ResData);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getAllContents();
  }, [ctx.isLoggedIn]);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");

    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
      setIsScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    const mobileMenu = document.getElementById("primary-menu-trigger");
    mobileMenu.style.display = "none";
    if (isLoading) {
      mobileMenu.style.display = "none";
    } else {
      mobileMenu.style.display = "flex";
    }
  }, [isLoading]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fikrimiz</title>
        <meta name="description" content="Sosyal Yaratıcılık Başlıyor" />
        <meta
          name="keywords"
          content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
        />
        <meta property="og:image" content="/assets/img/fikrimiz-org-3.png" />
      </Helmet>
      {isLoading ? (
        <Loader
          style={{ textAlign: "center" }}
          type="ThreeDots"
          color="#31F4E8"
          height={100}
          width={100}
        />
      ) : (
        pageContent && (
          <div>
            <Slider pageContent={pageContent} />
            <Content pageContent={pageContent} />
          </div>
        )
      )}
    </div>
  );
};

export default Home;

// sendIdea.js
import React, { useState, useEffect, useContext } from "react";
import StarRating from "../UI/starComponent";
import AuthContext from "../../store/authcontext";
import axios from "axios";
import * as constValues from "../../utilities/constants";
import { NotificationManager } from "react-notifications";

import Loader from "react-loader-spinner";

import useForm from "../UI/useForm";
import validate from "../UI/validations/ideaCommentFormValidation";
import IdeaEditForm from "./ideaEditForm";

const IdeaCommentForm = (props) => {
  let projectId = props.projectId; // project id
  let ideaId = props.ideaId; // idea id

  const [ideaDetail, setIdeaDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCommentSend, setIsCommentSend] = useState(false);
  const ctx = useContext(AuthContext);

  const { values, handleChange, handleSubmit, errors } = useForm(
    sendForm,
    validate
  );

  useEffect(() => {
    const getIdeaDetail = () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      let data = {
        projectId: projectId,
        ideaId: ideaId,
      };
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(constValues.API_URL + constValues.GET_IDEA_DETAIL, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: data,
          })
          .then((response) => {
            setIdeaDetail(response.data.ResData);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getIdeaDetail();
  }, [ctx.isLoggedIn, ideaId, isCommentSend, props.ideaFormCrud]);

  // send comment
  function sendForm() {
    setIsCommentSend(true);
    postForm(values.comment);
  }
  const postForm = (comment) => {
    const userToken = localStorage.getItem("REACT_TOKEN_USER");
    const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
    if (userToken !== null || anonymousToken !== null) {
      axios
        .post(constValues.API_URL + constValues.ADD_COMMENT_TO_IDEA, {
          ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
          ReqData: {
            ideaId: ideaId,
            projectId: projectId,
            comment: comment,
          },
        })
        .then((response) => {
          NotificationManager.info(response.data.ResMessage);
          setIsCommentSend(false);
          values.comment = "";
          props.setIdeaFormCrud(!props.ideaFormCrud);
        })
        .catch((error) => console.error("Error", error));
    }
  };

  return (
    <div
      id="ideaCommentedModal"
      className="modal fade bs-example-modal-scrollable show"
      tabIndex={-1}
      aria-labelledby="ideaCommentedModal"
      style={{
        display: "none",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg">
        {isLoading ? (
          <Loader
            style={{ textAlign: "center" }}
            type="ThreeDots"
            color="#31F4E8"
            height={50}
            width={50}
          />
        ) : (
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  {ideaDetail.caption}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12">
                  {ideaDetail && ideaDetail.ownIdea ? (
                    <IdeaEditForm
                      ideaObj={props.ideaObj}
                      projectId={projectId}
                      ideaList={props.ideaList}
                      setIdeaFormCrud={props.setIdeaFormCrud}
                      ideaFormCrud={props.ideaFormCrud}
                      setShowCommentBox={props.setShowCommentBox}
                    />
                  ) : (
                    <div className="form-group">
                      <p>{ideaDetail.idea}</p>
                    </div>
                  )}

                  {ideaDetail && !ideaDetail.ownIdea && (
                    <div className="form-group">
                      <label>Puan Ver</label>
                      <StarRating
                        totalStars={3}
                        currentStar={ideaDetail.ownStarCount}
                        projectId={projectId}
                        ideaId={ideaId}
                        setIdeaFormCrud={props.setIdeaFormCrud}
                        ideaFormCrud={props.ideaFormCrud}
                      />
                    </div>
                  )}
                </div>

                <div className="divider" />

                <ol className="commentlist clearfix">
                  <li
                    className="comment even thread-even depth-1"
                    id="li-comment-1"
                    style={{ margin: 0 }}
                  >
                    {isLoading ? (
                      <Loader
                        style={{ textAlign: "center" }}
                        type="ThreeDots"
                        color="#31F4E8"
                        height={50}
                        width={50}
                      />
                    ) : ideaDetail &&
                      ideaDetail.comments &&
                      ideaDetail.comments.length > 0 ? (
                      ideaDetail.comments.map((cmments, i) => {
                        return (
                          <div
                            id={i}
                            key={i}
                            className="comment-wrap clearfix commentIdea"
                            style={
                              cmments.isOwn
                                ? {
                                    backgroundColor: "#3D84F6",
                                    margin: "1rem",
                                    color: "#F9F9F9",
                                  }
                                : {
                                    backgroundColor: "#E6E5EA",
                                    margin: "1rem",
                                    color: "#000",
                                  }
                            }
                          >
                            <div className="comment-content clearfix">
                              <p style={{ margin: 0 }}>{cmments.comment}</p>
                            </div>
                            <div className="clear" />
                          </div>
                        );
                      })
                    ) : (
                      <p>Yorum bulunamadı...</p>
                    )}
                  </li>
                </ol>
              </div>
              <div className="modal-footer">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">YORUM YAZ:</label>
                    <input
                      type="text"
                      id="comment"
                      name="comment"
                      className="form-control not-dark"
                      placeholder="Yorumunu yaz"
                      onChange={handleChange}
                      value={values.comment || ""}
                      required
                    />
                    {errors.comment && (
                      <span className="text-danger">{errors.comment}</span>
                    )}
                  </div>
                  {isCommentSend ? (
                    <Loader
                      style={{ textAlign: "center" }}
                      type="ThreeDots"
                      color="#31F4E8"
                      height={50}
                      width={50}
                    />
                  ) : (
                    <input
                      type="submit"
                      className="btn btn-primary mt-3"
                      value="Yorum Gönder"
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default IdeaCommentForm;

// detailSidebar.js
import React, { useState, Component, useEffect, useContext } from "react";
import Moment from "moment";
import {
  BrowserRouter as Router,
  useParams,
  useLocation,
} from "react-router-dom";
import "moment/locale/tr";

import IdeaForm from "../../forms/ideaForm";
import DetailIdeaList from "../parts/detailIdeaList";
import DetailGroupFriends from "../parts/detailGroupFriends";
import AuthContext from "../../../store/authcontext";
import Login from "../../forms/login";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import * as constValues from "../../../utilities/constants";

const DetailSidebar = (props) => {
  const [loginModalStatus, setLoginModalStatus] = useState(false);
  const [groupButtonStatus, setGroupButtonStatus] = useState(false);
  const [ideaFormCrud, setIdeaFormCrud] = useState(false);
  const ctx = useContext(AuthContext);
  let { projectId } = useParams();

  useEffect(() => {
    const groupStatus = () => {
      if (ctx.isLoggedIn) {
        // if user is groupowner
        if (props.isGroupOwner) {
          if (
            props.friendsArrayList != null &&
            props.friendsArrayList.length < 4
          ) {
            setGroupButtonStatus(true);
          }
        } else {
          if (
            props.friendsArrayList != null &&
            props.friendsArrayList.length == 0
          ) {
            setGroupButtonStatus(true);
          } else {
            setGroupButtonStatus(false);
          }
        }
      } else {
        setGroupButtonStatus(false);
      }
    };
    groupStatus();
  });

  const ProjectGroupPath = () => {
    let navigateToProjectGroup =
      "/projeler/davet/" +
      projectId +
      "/" +
      encodeURIComponent(props.projectTitle); // project id
    window.location.href = navigateToProjectGroup;
  };
  const GoToProjectGroups = () => {
    /** project group navigation (props.isInvolved==true && props.isGroupOwner==false) */
    if (ctx.isLoggedIn) {
      // if user is groupowner
      if (props.isGroupOwner) {
        if (
          props.friendsArrayList != null &&
          props.friendsArrayList.length < 4
        ) {
          ProjectGroupPath();
        }
      } else {
        if (
          props.friendsArrayList != null &&
          props.friendsArrayList.length == 0
        ) {
          ProjectGroupPath();
        } else {
          NotificationManager.info(
            "Proje lideri olmadan projeye kişi davet edemezsin"
          );
        }
      }
    } else {
      NotificationManager.info("Üye olmanız gerekmektedir");
    }
  };

  const GoToProjectIdea = () => {
    //if user not loggedin or register the website than show the login popup and do the process
    if (!ctx.isLoggedIn) {
      localStorage.setItem("COMES_FROM_IDEA", "Y");
      setLoginModalStatus(true);
    } else {
      // get all ideas list
      if (props.ideas.length > 0) {
        // check user has any idea before
        let ownIdea = 0;
        for (let i = 0; i < props.ideas.length; i++) {
          if (props.ideas[i].ownIdea) {
            ownIdea += 1;
          }
        }
        // if has idea
        if (ownIdea > 0) {
          if (props.friendsArrayList.length > 0) {
            if (ownIdea != 2) {
              props.setModalIdeaForm(true);
            } else {
              NotificationManager.info(
                "Kullanıcılar projelere en fazla 2 fikir verebilir"
              );
            }
          } else {
            NotificationManager.info(
              "Arkadaşınızı davet edin, daha çok fikir girme sayısına ulaşın"
            );
          }
        } else {
          props.setModalIdeaForm(true);
        }
      } else {
        props.setModalIdeaForm(true);
      }
    }
  };

  return (
    <div className="sidebar col-lg-4">
      {loginModalStatus && (
        <Login
          loginModal={setLoginModalStatus}
          modalIdeaForm={props.modalIdeaForm}
          setModalIdeaForm={props.setModalIdeaForm}
        />
      )}
      <div className="sidebar-widgets-wrap">
        <div>
          <h3 className="font-weight-bold h2 mb-2 color">
            {Moment.utc(props.deadline).format("Do MMMM YYYY")}
          </h3>
          <span className="text-uppercase text-smaller op-07">
            Son Katılım Tarihi
          </span>
          <ul className="skills mt-4">
            <li data-percent={73} style={{ height: "7px" }}>
              <span className="d-flex justify-content-between w-100">
                <span className="counter counter-xs h6">
                  {props.ideas ? (
                    <span
                      data-from={0}
                      data-to={props.ideas.length}
                      data-refresh-interval={10}
                      data-speed={2000}
                    />
                  ) : null}
                  <strong></strong> Fikir Eklendi
                </span>
                {/**<span className="counter counter-xs h6"><span data-from={0} data-to={20} data-refresh-interval={3} data-speed={1200} /> Days Left</span> */}
                <span className="counter counter-xs h6">
                  {props.remainingTime}
                </span>
              </span>
              <div className="progress" />
            </li>
          </ul>
        </div>
        <div className="line line-sm" />

        <div className="clear mt-4" />
        <button
          className="button button-xlarge font-weight-semibold button-rounded ls0 nott ml-0 my-4 btn-block text-center"
          onClick={() => GoToProjectIdea()}
        >
          Fikir Gönder
        </button>
        {ctx.isLoggedIn && groupButtonStatus && (
          <React.Fragment>
            <button
              className="button button-3d button-xlarge button-rounded button-amber ls0 nott ml-0 my-4 btn-block text-center"
              onClick={() => GoToProjectGroups()}
            >
              {props.isGroupOwner ? "Arkadaş ekle" : "Grup Oluştur"}
            </button>
          </React.Fragment>
        )}

        {props.modalIdeaForm && (
          <IdeaForm
            id={props.id}
            modalIdeaForm={props.setModalIdeaForm}
            ideaFormCrud={ideaFormCrud}
            setIdeaFormCrud={setIdeaFormCrud}
          />
        )}
      </div>
      {ctx.isLoggedIn && (
        <DetailIdeaList
          projectId={props.id}
          projectTitle={props.projectTitle}
          ideaFormCrud={ideaFormCrud}
          setIdeaFormCrud={setIdeaFormCrud}
        />
      )}

      {props.isInvolved ? (
        <DetailGroupFriends friendsArrayList={props.friendsArrayList} />
      ) : null}
    </div>
  );
};
export default DetailSidebar;

import {NotificationContainer, NotificationManager} from 'react-notifications';
const { default: axios } = require("axios");
const { API_URL, API_GET_TOKEN } = require("./constants");

const token = () => {
  const tokenUrl = `${API_URL}${API_GET_TOKEN}`;

  const setToken = (token) => {
    if (
      localStorage.getItem("REACT_TOKEN_USER") === null &&
      localStorage.getItem("REACT_TOKEN_ANONYMOUS") === null
    ) {
      localStorage.setItem("REACT_TOKEN_ANONYMOUS", token);
    }
  };

  const getToken = () => {
    const body = {
      ReqToken: "",
      ReqData: {},
    };
    axios
      .post(tokenUrl, body)
      .then(function (response) {
        const res = response.data;
        // sonucu handle et
        if (res.ResCode !== 200) {
          NotificationManager.info(res.ResMessage);
        } else {
          // kayit basarili burada gerekli islemleri yapacagiz
          setToken(response.data.ResData.token);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return {
    getToken: getToken,
  };
};

export default token;

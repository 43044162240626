// Slider.js
import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../store/authcontext";
import Login from "../../forms/login";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

const Slider = (props) => {
  const ctx = useContext(AuthContext);
  const [loginModal, setLoginModal] = useState(false);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, []);

  return (
    <section
      id="slider"
      className="slider-element min-vh-100 include-header"
      style={{
        background:
          'url("/assets/img/background-2.svg") no-repeat center bottom / cover',
      }}
    >
      {loginModal && <Login loginModal={setLoginModal} />}
      {props.pageContent !== null && (
        <div className="slider-inner">
          <div className="vertical-middle">
            <div className="container py-5">
              <div className="row">
                <div className="col-lg-6 col-md-8">
                  <div className="slider-title">
                    {ReactHtmlParser(
                      props.pageContent.find(
                        (x) => x.contentKey === "home.slider.title"
                      ).managementContent
                    )}
                    <div className="text-muted">
                      {ReactHtmlParser(
                        props.pageContent.find(
                          (x) => x.contentKey === "home.slider.subtitle"
                        ).managementContent
                      )}
                    </div>
                  </div>
                  <Link
                    className="button button-large font-weight-semibold button-rounded ls0 nott ml-0"
                    to="/projeler"
                  >
                    {props.pageContent
                      .find(
                        (x) => x.contentKey === "home.slider.leftbutton.title"
                      )
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")}
                  </Link>
                  {!ctx.isLoggedIn ? (
                    <button
                      onClick={() => setLoginModal(true)}
                      className="button button-large font-weight-semibold button-rounded ls0 nott"
                    >
                      {props.pageContent
                        .find(
                          (x) =>
                            x.contentKey === "home.slider.rightbutton.title"
                        )
                        .managementContent.replace("<p>", "")
                        .replace("</p>", "")}
                    </button>
                  ) : null}

                  <br />
                </div>
              </div>
            </div>
          </div>
          <img
            src="/assets/img/Anasayfa3.png"
            alt=""
            className="slider-img parallax"
            data-start="margin-top: 0px;"
            data-400="margin-top: 50px;"
          />
        </div>
      )}
    </section>
  );
};
export default Slider;

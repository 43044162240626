// notifications.js
import React, { useState, useEffect, useContext } from "react";
/** api part */
import * as constValues from "../../../utilities/constants";
import axios from "axios";
import AuthContext from "../../../store/authcontext";

/** parts */
import NotificationModal from "../parts/notificationModal";
import Loader from "react-loader-spinner";

const Notification = (props) => {
  const ctx = useContext(AuthContext);
  const [projectNotifications, setProjectNotifications] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationModal, setNotificationModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);

  useEffect(() => {
    const getNotifications = () => {
      ctx.setIsNotificationRead(true);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(constValues.API_URL + constValues.GET_PROJECT_NOTIFICATIONS, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: props.project.id,
          })
          .then((response) => {
            setProjectNotifications(response.data.ResData);
            setIsLoading(false);
            ctx.setIsNotificationRead(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getNotifications();
  }, [props.loadNotification]);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, []);

  return (
    <React.Fragment>
      <div
        className="mfp-bg mfp-no-margins mfp-fade mfp-ready"
        onClick={() => {
          setIsLoading(true);
          props.setLoadNotification(false);
        }}
      ></div>
      <div className="myModal" style={{ overflowY: "auto", maxHeight: "90%" }}>
        <div className="modal-body">
          <div className="modal-content" style={{ border: "none" }}>
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                {props.project.name} Bildirimleri
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  setIsLoading(true);
                  props.setLoadNotification(false);
                }}
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <ul className="list-group">
                {isLoading ? (
                  <Loader
                    style={{ textAlign: "center" }}
                    type="ThreeDots"
                    color="#31F4E8"
                    height={100}
                    width={100}
                  />
                ) : projectNotifications ? (
                  projectNotifications.map((detail, i) => {
                    return (
                      <React.Fragment>
                        {notificationModal &&
                          selectedNotification === detail.$id && (
                            <NotificationModal
                              invitationId={detail.invitationId}
                              setNotificationModal={setNotificationModal}
                            />
                          )}
                        <li className="list-group-item" key={i}>
                          {detail.notification}
                          {detail.type ==
                            constValues.NOTIFICATION_TYPE_INVITE &&
                            !props.project.isInvolved && (
                              <div
                                className="cursorPointer"
                                onClick={() => {
                                  setNotificationModal(true);
                                  setSelectedNotification(detail.$id);
                                }}
                              >
                                <span
                                  className="badge badge-secondary float-right"
                                  style={{ marginTop: "3px" }}
                                  data-toggle="modal"
                                  data-target="#groupNotificationModal"
                                >
                                  <i className="icon-question"></i>
                                </span>
                              </div>
                            )}
                        </li>
                      </React.Fragment>
                    );
                  })
                ) : (
                  "Bildirim yok"
                )}
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setIsLoading(true);
                  props.setLoadNotification(false);
                }}
              >
                Kapat
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    // <div
    //   className="modal fade"
    //   id="myModal"
    //   tabIndex={-1}
    //   aria-labelledby="myModalLabel"
    //   style={{ display: "none" }}
    //   aria-hidden="true"
    //   onClick={() => {
    //     setProjectNotifications(null);
    //     setIsLoading(true);
    //     props.setLoadNotification(false);
    //   }}
    // >
    //
    // </div>
  );
};

export default Notification;

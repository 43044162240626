// detailPostContent .js
import React, { useState, Component, useEffect, useContext } from "react";
import AuthContext from "../../../store/authcontext";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  BrowserRouter as Router,
  useParams,
  useLocation,
} from "react-router-dom";
import Login from "../../forms/login";

const DetailPostContent = (props) => {
  const [loginModalStatus, setLoginModalStatus] = useState(false);
  const [groupButtonStatus, setGroupButtonStatus] = useState(false);
  const ctx = useContext(AuthContext);

  useEffect(() => {}, []);
  let { projectId } = useParams();

  const ProjectGroupPath = () => {
    let navigateToProjectGroup =
      "/projeler/davet/" +
      projectId +
      "/" +
      encodeURIComponent(props.projectTitle); // project id
    window.location.href = navigateToProjectGroup;
  };

  useEffect(() => {
    const groupStatus = () => {
      if (ctx.isLoggedIn) {
        // if user is groupowner
        if (props.isGroupOwner) {
          if (
            props.friendsArrayList != null &&
            props.friendsArrayList.length < 4
          ) {
            setGroupButtonStatus(true);
          }
        } else {
          if (
            props.friendsArrayList != null &&
            props.friendsArrayList.length == 0
          ) {
            setGroupButtonStatus(true);
          } else {
            setGroupButtonStatus(false);
          }
        }
      } else {
        setGroupButtonStatus(false);
      }
    };
    groupStatus();
  });

  const GoToProjectGroups = () => {
    /** project group navigation */
    // check user is isloggedin
    if (ctx.isLoggedIn) {
      // if user is groupowner
      if (props.isGroupOwner) {
        if (
          props.friendsArrayList != null &&
          props.friendsArrayList.length < 4
        ) {
          ProjectGroupPath();
        }
      } else {
        if (
          props.friendsArrayList != null &&
          props.friendsArrayList.length == 0
        ) {
          ProjectGroupPath();
        } else {
          NotificationManager.info(
            "Proje lideri olmadan projeye kişi davet edemezsin"
          );
        }
      }
    } else {
      NotificationManager.info("Üye olmanız gerekmektedir");
    }
  };

  const GoToProjectIdea = () => {
    //props.setModalIdeaForm(true);
    // get all ideas list

    //if user not loggedin or register the website than show the login popup and do the process
    if (!ctx.isLoggedIn) {
      localStorage.setItem("COMES_FROM_IDEA", "Y");
      setLoginModalStatus(true);
    }
    //if user logged in then chech ideas
    else {
      if (props.ideas.length > 0) {
        // check user has any idea before
        let ownIdea = 0;
        for (let i = 0; i < props.ideas.length; i++) {
          if (props.ideas[i].ownIdea) {
            ownIdea += 1;
          }
        }
        // if has idea
        if (ownIdea > 0) {
          if (props.friendsArrayList.length > 0) {
            if (ownIdea != 2) {
              props.setModalIdeaForm(true);
            } else {
              NotificationManager.info(
                "Kullanıcılar projelere en fazla 2 fikir verebilir"
              );
            }
          } else {
            NotificationManager.info(
              "Arkadaşınızı davet edin, daha çok fikir girme sayısına ulaşın"
            );
          }
        } else {
          props.setModalIdeaForm(true);
        }
      } else {
        props.setModalIdeaForm(true);
      }
    }
  };
  
  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  return (
    <div className="postcontent col-lg-8">
      {loginModalStatus && (
        <Login
          loginModal={setLoginModalStatus}
          modalIdeaForm={props.modalIdeaForm}
          setModalIdeaForm={props.setModalIdeaForm}
        />
      )}
      <div className="single-post mb-0">
        {/* Single Post
								============================================= */}
        <div className="entry clearfix">
          {/* Entry Image
									============================================= */}
          <div className="entry-image mt-2">
            <div className="mb-2">
              {/* .buraya resim gelecek */}
              {props.medias ? (
                <img
                  src={props.medias[0].url}
                  width="912"
                  height="513"
                  alt={props.medias[0].caption}
                ></img>
              ) : null}
            </div>
          </div>
          {/* .entry-image end */}
          {/* Page Sub Menu
									============================================= */}
          <div id="page-menu" data-mobile-sticky="true">
            <div id="page-menu-wrap">
              <div className="container">
                <div
                  className="page-menu-row"
                  style={{ justifyContent: "space-between" }}
                >
                  <nav
                    className="page-menu-nav one-page-menu"
                    data-offset={150}
                  >
                    <ul className="page-menu-container">
                      {/**<li className="page-menu-item"><a className="ml-0" data-href="#section-desc"><div>Proje Genel Bilgiler</div></a></li>*/}
                      <li className="page-menu-item">
                        <a href="#" data-href="#section-updates">
                          <div>Proje Genel Bilgiler</div>
                        </a>
                      </li>
                      <li className="page-menu-item">
                        <a href="#" data-href="#section-faqs">
                          <div>SSS</div>
                        </a>
                      </li>
                      <li className="page-menu-item">
                        <a href="#" data-href="#section-gallery">
                          <div>Görseller</div>
                        </a>
                      </li>
                      {/**<li className="page-menu-item"><a href="#" data-href="#section-reviews"><div>Reviews</div></a></li> */}
                    </ul>
                  </nav>

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <button
                      onClick={() => GoToProjectIdea()}
                      className="button font-weight-semibold button-rounded ls0 nott projectDetailBtn"
                    >
                      Fikir Gönder
                    </button>
                    {ctx.isLoggedIn && groupButtonStatus && (
                      <button
                        className="button font-weight-semibold button-rounded button-amber ls0 nott projectDetailBtn"
                        onClick={() => GoToProjectGroups()}
                      >
                        {props.isGroupOwner ? "Arkadaş ekle" : "Grup Oluştur"}
                      </button>
                    )}
                  </div>

                  <div id="page-menu-trigger">
                    <i className="icon-reorder" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* #page-menu end */}
          {/* Entry Content
									============================================= */}
          <div className="entry-content mt-4">
            <div id="section-updates" className="page-section">
              <h2>Proje Bilgileri</h2>
              {props.details
                ? props.details.map((detail, i) => {
                    return (
                      <div className="card mb-4" key={i}>
                        <div className="card-body bg-light">
                          <h3 className="mb-2">{detail.caption}</h3>
                          <p className="mb-0 mt-3">
                            {ReactHtmlParser( urlify(detail.description))}
                          </p>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>

            <div className="line" />

            <div id="section-faqs" className="page-section">
              <h2>Sıkça Sorulan Sorular</h2>
              <div className="toggle toggle-bg">
                <div className="toggle-header">
                  <div className="toggle-icon">
                    <i className="toggle-closed icon-question-sign" />
                    <i className="toggle-open icon-question-sign" />
                  </div>
                  <div className="toggle-title">
                    {props.pageContent
                      ? ReactHtmlParser(
                          props.pageContent
                            .find(
                              (x) => x.contentKey === "projedetay.sss.title1"
                            )
                            .managementContent.replace("<p>", "")
                            .replace("</p>", "")
                        )
                      : null}
                  </div>
                </div>
                <div className="toggle-content">
                  {props.pageContent
                    ? ReactHtmlParser(
                        props.pageContent
                          .find(
                            (x) => x.contentKey === "projedetay.sss.subtitle1"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "")
                      )
                    : null}
                </div>
              </div>
              <div className="toggle toggle-bg">
                <div className="toggle-header">
                  <div className="toggle-icon">
                    <i className="toggle-closed icon-question-sign" />
                    <i className="toggle-open icon-question-sign" />
                  </div>
                  <div className="toggle-title">
                    {props.pageContent
                      ? ReactHtmlParser(
                          props.pageContent
                            .find(
                              (x) => x.contentKey === "projedetay.sss.title2"
                            )
                            .managementContent.replace("<p>", "")
                            .replace("</p>", "")
                        )
                      : null}
                  </div>
                </div>
                <div className="toggle-content">
                  {props.pageContent
                    ? ReactHtmlParser(
                        props.pageContent
                          .find(
                            (x) => x.contentKey === "projedetay.sss.subtitle2"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "")
                      )
                    : null}
                </div>
              </div>
              <div className="toggle toggle-bg">
                <div className="toggle-header">
                  <div className="toggle-icon">
                    <i className="toggle-closed icon-question-sign" />
                    <i className="toggle-open icon-question-sign" />
                  </div>
                  <div className="toggle-title">
                    {props.pageContent
                      ? ReactHtmlParser(
                          props.pageContent
                            .find(
                              (x) => x.contentKey === "projedetay.sss.title3"
                            )
                            .managementContent.replace("<p>", "")
                            .replace("</p>", "")
                        )
                      : null}
                  </div>
                </div>
                <div className="toggle-content">
                  {props.pageContent
                    ? ReactHtmlParser(
                        props.pageContent
                          .find(
                            (x) => x.contentKey === "projedetay.sss.subtitle3"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "")
                      )
                    : null}
                </div>
              </div>
            </div>

            <div className="line" />

            <div id="section-gallery" className="page-section">
              <h2>Görseller</h2>
              <div
                className="masonry-thumbs grid-container grid-6"
                data-big={3}
                data-lightbox="gallery"
              >
                {props.medias
                  ? props.medias.map((media, i) => {
                      return (
                        <a
                          className="grid-item"
                          href={media.url}
                          data-lightbox="gallery-item"
                          key={i}
                        >
                          <img src={media.url} alt={media.caption} />
                        </a>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailPostContent;

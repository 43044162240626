import React, { Component, useEffect, useState, useContext } from "react";
import Loader from "react-loader-spinner";
import { NotificationContainer, NotificationManager } from 'react-notifications';


/** api  */
import * as constValues from "../../utilities/constants";
import useForm from "../UI/useForm";
import validate from '../UI/validations/contactFormValidation'; // buraya contact formnunun validasyonu gelecek
import axios from "axios";

import AuthContext from '../../store/authcontext';


const ContactForm = props => {

  const { values, handleChange, handleSubmit, errors } = useForm(sendForm, validate);
  const [loading, setLoading] = useState(false);
  const [responseResult, setResponseResult] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const ctx = useContext(AuthContext);


  function sendForm() {
    getRecaptchaToken();
  };

  /** Validate reCaptcha */

  const getRecaptchaToken = e => {
    //e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(constValues.SITE_KEY, { action: 'submit' }).then(token => {
        submitData(token);
      });
    });
  }



  const submitData = token => {
    axios
      .post(constValues.API_URL + constValues.GET_VALIDATE_RECAPTCHA, {
        ReqToken: ctx.isLoggedIn ? localStorage.getItem("REACT_TOKEN_USER") : localStorage.getItem("REACT_TOKEN_ANONYMOUS"),
        ReqData: {
          response: token,
        },
      })
      .then((response) => {
        if (response.data.ResData.success == true) {
          setLoading(true);
          postForm(values.formSubject, values.formMessage);
        } else {
          setCaptchaError(true);
        }
      })
      .catch((error) => console.error("Error", error));
  }

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    }

    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${constValues.SITE_KEY}`, function () {
      console.log("Script loaded!");
    });


  }, []);



  /** post contact form */
  const postForm = (subject, message) => {
    axios
      .post(constValues.API_URL + constValues.API_GET_SEND_MESSAGE, {
        ReqToken: ctx.isLoggedIn ? localStorage.getItem("REACT_TOKEN_USER") : localStorage.getItem("REACT_TOKEN_ANONYMOUS"),
        ReqData: {
          caption: subject,
          message: message,
        },
      })
      .then((response) => {
        setLoading(false);
        NotificationManager.info(response.data.ResMessage);
        window.location.reload();
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <React.Fragment>
      <div className="row align-items-stretch col-mb-50 mb-0">
        {/* Contact Form
						============================================= */}
        <div className="col-lg-6">
          <div className="fancy-title title-border">
            <h3>Lütfen gerekli olan bilgileri doldurunuz</h3>
          </div>
          <div className="form-widget">
            {captchaError && (
              <span className="text-danger">reCaptcha Hatası</span>
            )}
            <div className="form-result" />
            <div className="mb-0">
              <div className="form-process">
                <div className="css3-spinner">
                  <div className="css3-spinner-scaler" />
                </div>
              </div>
              <div className="row">
                <div className="w-100" />
                <div className="col-md-12 form-group">
                  <label htmlFor="template-contactform-subject">Konu <small>*</small></label>
                  <input type="text"
                    id="formSubject"
                    name="formSubject"
                    value=""
                    className="required sm-form-control"
                    onChange={handleChange}
                    value={values.formSubject || ''}
                    required
                  />
                  {errors.formSubject && (
                    <span className="text-danger">{errors.formSubject}</span>
                  )}
                </div>
                <div className="w-100" />

                <div className="col-12 form-group">
                  <label htmlFor="template-contactform-message">Mesajınız <small>*</small></label>
                  <textarea className="required sm-form-control"
                    id="formMessage"
                    name="formMessage"
                    rows={6} cols={30}
                    onChange={handleChange}
                    value={values.formMessage || ''}
                    required
                  />
                  {errors.formMessage && (
                    <span className="text-danger">{errors.formMessage}</span>
                  )}
                </div>
                {/**
                                <div className="col-12 form-group d-none">
                                    <input type="text" 
                                           id="template-contactform-botcheck" 
                                           name="template-contactform-botcheck" 
                                           value="" 
                                           className="sm-form-control" 
                                    />
                                </div>
                                 */}
                <div className="col-12 form-group">
                  {loading ?
                    <Loader style={{ textAlign: 'center' }} type="ThreeDots" color="#31F4E8" height={50} width={50} />
                    :
                    <input name="submit" type="submit" onClick={() => handleSubmit()} id="submit-button-contact" tabIndex={5} value="Gönder" className="button button-3d m-0" />
                  }



                </div>
              </div>
              <input type="hidden" name="prefix" defaultValue="template-contactform-" />
            </div>
          </div>
        </div>{/* Contact Form End */}
        {/* Google Map
						============================================= */}
        <div className="col-lg-6 min-vh-50">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d782.0611837914156!2d27.20753278949898!3d38.36645951197778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b9618f96458f3d%3A0x8938fde17f4e4463!2sDepark%20Beta!5e0!3m2!1str!2str!4v1623767187619!5m2!1str!2str" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
        </div>{/* Google Map End */}
      </div>

    </React.Fragment>
  );
}
export default ContactForm;


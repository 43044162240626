// ProjectDetail.js
import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

/** api part */
import * as constValues from "../../utilities/constants";
import axios from "axios";

/** parts */
import ProjectHeader from "../projects/parts/projectHeader";
import ProjectContent from "../projects/parts/projectContent";

import AuthContext from "../../store/authcontext";

import { Helmet } from "react-helmet";

const Projects = (props) => {
  const [projectList, setProjectList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const getProjects = () => {
      setIsLoading(true);
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (userToken !== null || anonymousToken !== null) {
        axios
          .post(constValues.API_URL + constValues.API_GET_PROJECTS, {
            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
            ReqData: {},
          })
          .then((response) => {
            setProjectList(response.data.ResData);
            setIsLoading(false);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getProjects();
  }, [ctx.isLoggedIn]);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, []);

  useEffect(() => {
    const mobileMenu = document.getElementById("primary-menu-trigger");
    mobileMenu.style.display = "none";
    if (isLoading) {
      mobileMenu.style.display = "none";
    } else {
      mobileMenu.style.display = "flex";
    }
  }, [isLoading]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fikrimiz - Projeler</title>
        <meta name="description" content="Sosyal Yaratıcılık Başlıyor" />
        <meta
          name="keywords"
          content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
        />
        <meta property="og:image" content="/assets/img/fikrimiz-org-3.png" />
      </Helmet>
      <section id="content">
        <div className="content-wrap pt-0">
          <ProjectHeader />
          {isLoading ? (
            <Loader
              style={{ textAlign: "center" }}
              type="ThreeDots"
              color="#31F4E8"
              height={100}
              width={100}
            />
          ) : (
            projectList && (
              <ProjectContent
                projectList={projectList}
                setProjectList={setProjectList}
              />
            )
          )}
        </div>
      </section>
    </div>
  );
};

export default Projects;

//export const API_URL = "http://localhost:49840/api/json/";
export const API_URL = "https://testapi.videa.com.tr/api/json/";
//export const API_URL = "http://192.168.1.12:49840/api/json/";

export const API_GET_SITEMANAGEMENT_VALUES = "SiteManagement/GetPageContents";
export const API_GET_TOKEN = "Users/GetToken";
export const API_GET_PROJECTS = "Projects/GetProjects";
export const API_GET_PROJECT_DETAIL = "Projects/GetProjectDetail";
export const API_GET_SEND_IDEA = "Projects/AddProjectIdea";
export const API_GET_UPDATE_IDEA = "Projects/UpdateIdea";
export const API_GET_DELETE_IDEA = "Projects/DeleteIdea";
export const API_GET_WINNER_GENERAL = "Users/GetWinnerListGeneral";
export const API_GET_WINNER_RELATED = "Users/GetWinnerListRelated";
export const API_GET_SEND_MESSAGE = "Users/SendMessage";
export const SIGNUP_URL = "/Users/SignUp";
export const LOGIN_URL = "/Users/WebLogin";
export const FORGOT_PASSWORD_URL = "/Users/ForgotPassword";
export const UPDATE_PROFILE_URL = "/Users/WebUpdateProfile";
export const GET_USER_URL = "/Users/GetUser";
export const GET_USER_ALL_IDEAS_URL = "projects/GetAllUserIdeas";
export const GET_HOMEPAGE_PROJECTS_URL = "Projects/GetMainPageItems";
export const GET_BLOG = "Projects/GetBlog";
export const GET_PROJECT_NOTIFICATIONS = "Projects/GetProjectNotifications";
export const GET_PROJECT_GROUP_INVITES = "Projects/GetUsersForInvitation";
export const GET_PROJECT_GROUP_RELATED_INVITES =
  "Projects/GetRelatedUsersForInvitation";
export const GET_PROJECT_ACCEPT_INVITATION = "Projects/AcceptInvitation";
export const GET_PROJECT_REJECT_INVITATION = "Projects/RejectInvitation";
export const GET_PROJECT_CANCEL_INVITATION = "Projects/CancelInvitation";
export const GET_PROJECT_SEND_INVITATION = "Projects/SendInvitation";
export const VERIFY_PHONE_URL = "Users/VerifyPhone";
export const GET_USER_NOTIFICATION_SETTINGS = "Users/GetNotificationSettings";
export const UPDATE_USER_NOTIFICATION_SETTINGS =
  "Users/UpdateNotificationSettings";
export const GET_BLOGS = "Projects/GetBlogs";
export const GET_IDEA_DETAIL = "Projects/GetIdeaDetail";
export const GIVE_STAR_TO_IDEA = "Projects/GiveStarToIdea";
export const ADD_COMMENT_TO_IDEA = "Projects/AddCommentToIdea";
export const TOTAL_NOTIFICATION_COUNT = "Projects/GetNotificationCount";

/** Page Groups Name */
export const PAGE_GROUP_NAME_ANASAYFA = "Anasayfa";
export const PAGE_GROUP_NAME_FIKRIMIZNEDIR = "FikrimizNedir";
export const PAGE_GROUP_NAME_PROJEDETAY = "ProjeDetay";
export const PAGE_GROUP_NAME_LIDERLER = "Liderler";
export const PAGE_GROUP_NAME_PROJELER = "Projeler";
export const PAGE_GROUP_NAME_BLOGLAR = "Bloglar";
export const PAGE_GROUP_NAME_ILETISIM = "Iletisim";


/** Google reCaptcha */
export const SITE_KEY = "6Lcs_NwaAAAAAHL1HlsDRadZErnY8-9idX-wZug6";
export const GET_VALIDATE_RECAPTCHA = "Users/VerifyRecaptcha";

/** General Info */
export const PROJECT_TYPE = 1;
export const BLOG_TYPE = 2;

/** Notification types */
export const NOTIFICATION_TYPE_INVITE = 4;

/** Send Invitation Type */
export const INVITATION_TYPE_USER_ID = 1;
export const INVITATION_TYPE_FACEBOOK = 2;
export const INVITATION_TYPE_PHONE = 3;

/** facebook app id */
export const FACEBOOK_APP_ID = 494377211769675;

/** User Verify Status */
export const VERIFY_NOT_CONFIRMED = 1;
export const VERIFY_CONFIRMED = 2;

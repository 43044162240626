// sendIdea.js
import React, { Component, useEffect, useState, useContext } from "react";
import Loader from "react-loader-spinner";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

/** api  */
import * as constValues from "../../utilities/constants";
import useForm from "../UI/useForm";
import validate from "../UI/validations/ideaFormValidation";
import axios from "axios";
import AuthContext from "../../store/authcontext";

const IdeaForm = (props) => {
  let projectId = props.id; // project id
  const [caption, setCaption] = useState(null);
  const [idea, setIdea] = useState(null);

  const { values, handleChange, handleSubmit, errors } = useForm(
    sendForm,
    validate
  );

  const [isLoading, setIsLoading] = useState(false);
  const ctx = useContext(AuthContext);

  function sendForm() {
    postForm(values.caption, values.idea);
  }

  const postForm = (caption, idea) => {
    setIsLoading(true);
    axios
      .post(constValues.API_URL + constValues.API_GET_SEND_IDEA, {
        ReqToken: ctx.isLoggedIn
          ? localStorage.getItem("REACT_TOKEN_USER")
          : localStorage.getItem("REACT_TOKEN_ANONYMOUS"),
        ReqData: {
          projectId: projectId,
          caption: caption,
          idea: idea,
        },
      })
      .then((response) => {
        setIsLoading(false);
        //alert(response.data.ResMessage);
        NotificationManager.info(response.data.ResMessage);
        props.modalIdeaForm(false);
        props.setIdeaFormCrud(!props.ideaFormCrud);
      })
      .catch((error) => {
        console.error("Error", error);
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div
        className="mfp-bg mfp-no-margins mfp-fade mfp-ready"
        onClick={() => props.modalIdeaForm(false)}
        style={{ overflow: "auto" }}
      ></div>
      <div className="myModal">
        <div className="card mx-auto" style={{ maxWidth: "400px" }}>
          <div className="card-header py-3 bg-transparent center">
            <h3 className="mb-0 font-weight-normal">Fikrim</h3>
          </div>
          <div className="card-body mx-auto p-5">
            <div className="mb-0 row">
              <div className="col-12">
                <input
                  type="text"
                  id="caption"
                  name="caption"
                  className="form-control not-dark"
                  placeholder="Başlık (Max Karakter : 40)"
                  onChange={(event) => {
                    setCaption(event.target.value);
                    handleChange(event);
                  }}
                  value={values.caption || ""}
                  maxLength="40"
                  required
                />

                {caption && <span>{caption.length}/40</span>}

                {errors.caption && (
                  <span className="text-danger">{errors.caption}</span>
                )}
              </div>
              <div className="col-12 mt-2">
                <textarea
                  id="idea"
                  name="idea"
                  rows={4}
                  cols={50}
                  className="form-control not-dark"
                  placeholder="Fikrinizi belirtin (Max Karakter : 600)"
                  onChange={(event) => {
                    setIdea(event.target.value);
                    handleChange(event);
                  }}
                  value={values.idea || ""}
                  maxLength="600"
                  required
                />
                {idea && <span>{idea.length}/600</span>}
              </div>
              {errors.idea && (
                <span className="text-danger ml-3">{errors.idea}</span>
              )}
              <div className="col-12 mt-4">
                {isLoading ? (
                  <Loader
                    style={{ textAlign: "center" }}
                    type="ThreeDots"
                    color="#31F4E8"
                    height={50}
                    width={50}
                  />
                ) : (
                  <input
                    type="submit"
                    className="button btn-block m-0"
                    value="Gönder"
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          title="Close (Esc)"
          type="button"
          className="mfp-close2"
          onClick={() => props.modalIdeaForm(false)}
        >
          ×
        </button>
      </div>
    </React.Fragment>
  );
};

export default IdeaForm;

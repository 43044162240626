//import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-notifications/lib/notifications.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import React, { useState, useEffect } from "react";
import Home from "../src/components/home/home";
import Projects from "../src/components/projects/projects";
import Blogs from "../src/components/blogs/bloglist";
import ProjectDetail from "../src/components/projectDetail/projectDetail";
import LeaderBoard from "../src/components/leaderboard/leaderboard.js";
import Contact from "../src/components/contact/contact";
import Profile from "./components/profile/Profile";
import OurOpinion from "./components/ouropinion/ouropinion";
import BlogDetail from "./components/blogs/blog";
import ProjectGroup from "./components/projectGroup/projectGroup";
import token from "./utilities/token";
import Layout from "./components/layouts/Layout";

import ScrollToTop from "./components/layouts/ScrollToTop";

function App() {
  useEffect(() => {
    token().getToken();

    const tag = document.createElement("script");
    tag.src = tag;
    tag.id = "fscript";
    tag.src = "/assets/js/functions.js?v=1.1";
    tag.async = true;
    document.body.appendChild(tag);
  }, []);

  return (
    <div>
      <Router>
        <Layout>
          <ScrollToTop />
          <Switch>
            <Route path="/projeler/davet/:projectId/:projectTitle">
              <ProjectGroup />
            </Route>
            <Route path="/blog/blog-detay/:blogId">
              <BlogDetail />
            </Route>
            <Route path="/bloglar">
              <Blogs />
            </Route>
            {/** 
            <Route path="/fikrimiz-nedir">
              <OurOpinion />
            </Route>
            */}
            <Route path="/iletisim">
              <Contact />
            </Route>
            <Route path="/liderler">
              <LeaderBoard />
            </Route>
            <Route path="/projeler/proje-detay/:projectId">
              <ProjectDetail />
            </Route>
            <Route path="/projeler">
              <Projects />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Layout>
      </Router>
      <NotificationContainer />
    </div>
  );
}

export default App;

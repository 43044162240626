// Content.js
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import Moment from "moment";
import "moment/locale/tr";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  API_URL,
  API_GET_PROJECTS,
  GET_BLOGS,
} from "../../../utilities/constants";
import AuthContext from "../../../store/authcontext";
import "../../../App.css";
import ReactHtmlParser from "react-html-parser";
import Login from "../../forms/login";

const Content = (props) => {
  const [projects, setProjects] = useState(null);
  const [projectLength, setProjectLength] = useState(0);
  const [blogs, setBlogs] = useState(null);
  const [blogLength, setBlogLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const homePageProjectUrl = `${API_URL}${API_GET_PROJECTS}`;
  const homePageBlogUrl = `${API_URL}${GET_BLOGS}`;
  const ctx = useContext(AuthContext);
  const [loginModalStatus, setLoginModalStatus] = useState(false);

  useEffect(() => {
    const getHomePageProjects = () => {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (
        (ctx.isLoggedIn && userToken !== null) ||
        (!ctx.isLoggedIn && anonymousToken !== null)
      ) {
        const body = {
          ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
          ReqData: {},
        };
        axios
          .post(homePageProjectUrl, body)
          .then(function (response) {
            const res = response.data;
            // sonucu handle et
            if (res.ResCode !== 200) {
              //alert(res.ResMessage);
              NotificationManager.info(res.ResMessage);
              console.log("hatalarGETHOME", res);
            } else {
              // kayit basarili burada gerekli islemleri yapacagiz
              setProjects(res.ResData);
              /** project and blog lengths */
              let projectLength =
                res.ResData && res.ResData.length >= 3 ? 3 : res.ResData.length;
              setProjectLength(projectLength);
              setIsLoading(true);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };
    getHomePageProjects();
  }, [ctx.isLoggedIn]);

  useEffect(() => {
    const getBlogs = () => {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (
        (ctx.isLoggedIn && userToken !== null) ||
        (!ctx.isLoggedIn && anonymousToken !== null)
      ) {
        const body = {
          ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
          ReqData: {},
        };
        axios
          .post(homePageBlogUrl, body)
          .then(function (response) {
            const res = response.data;
            // sonucu handle et
            if (res.ResCode !== 200) {
              NotificationManager.info(res.ResMessage);
              console.log("hatalarGETHOME", res);
            } else {
              // kayit basarili burada gerekli islemleri yapacagiz
              setBlogs(res.ResData);
              /** project and blog lengths */
              let blogLength =
                res.ResData && res.ResData.length >= 3 ? 3 : res.ResData.length;
              setBlogLength(blogLength);
              setIsLoading(true);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };
    getBlogs();
  }, [ctx.isLoggedIn]);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, []);

  /** Project and Blog List  */
  let project = projects
    ? projects.slice(0, projectLength).map((item, index) => (
        <div
          className="col-lg-4 col-sm-6 mb-4"
          key={index}
          onMouseOver={() => {
            const desc1 = document.getElementsByClassName("desc1");
            const desc2 = document.getElementsByClassName("desc2");
            if (desc2.length !== 0) {
              desc1[index].style.display = "none";
              desc2[index].style.display = "block";
            }
          }}
          onMouseLeave={() => {
            const desc1 = document.getElementsByClassName("desc1");
            const desc2 = document.getElementsByClassName("desc2");
            if (desc2.length !== 0) {
              desc1[index].style.display = "block";
              desc2[index].style.display = "none";
            }
          }}
        >
          <Link to={`/projeler/proje-detay/${item.id}`}>
            <div className="i-products">
              <div className="products-image">
                <a href={`/projeler/proje-detay/${item.id}`}>
                  <img
                    src={
                      item.medias && item.medias.length > 0
                        ? item.medias[0].url
                        : null
                    }
                    alt={item.name}
                  />
                  {item.projectType && (
                    <span className="badge">
                      {item.projectType === "PUBLIC"
                        ? "Proje - Genel"
                        : "Proje - Özel"}
                    </span>
                  )}
                </a>
              </div>
              <div className="products-desc projectHeight">
                <h3>
                  <a href={`/projeler/proje-detay/${item.id}`}>{item.name}</a>
                </h3>
                <p className="desc1">
                  {item && item.details && item.details.length > 0
                    ? ReactHtmlParser(
                        item.details[0].description
                          .replace("<p>", "")
                          .replace("</p>", "")
                          .substring(0, 100) + "..."
                      )
                    : ""}
                </p>
                <p className="desc2" style={{ display: "none" }}>
                  {item && item.details && item.details.length > 1
                    ? ReactHtmlParser(
                        item.details[1].description
                          .replace("<p>", "")
                          .replace("</p>", "")
                          .substring(0, 100) + "..."
                      )
                    : ""}
                </p>
                <div className="clear" />
                <ul className="skills projectSkills">
                  <li data-percent={73}>
                    <span className="d-flex justify-content-between w-100">
                      {/**  <span className="counter"><span data-from={0} data-to={73} data-refresh-interval={10} data-speed={2000}>73</span><strong>%</strong> Funded</span>*/}
                      <span className="counter">
                        {/**  <span data-from={0} data-to={20} data-refresh-interval={3} data-speed={1200}></span>*/}{" "}
                        {item.remainingTime}
                      </span>
                    </span>
                    <div
                      className="progress skills-animated"
                      style={{ width: "73%" }}
                    />
                  </li>
                </ul>
                <div className="products-hoverlays">
                  <span className="products-location"></span>
                  <ul className="list-group-flush my-3 mb-0">
                    {ctx.isLoggedIn ? (
                      <li className="list-group-item">
                        {item && item.ideaCount ? item.ideaCount : 0} Fikir
                        Sayısı
                      </li>
                    ) : null}
                    <li className="list-group-item">
                      {item && item.companyName ? item.companyName : null}
                    </li>
                    <li className="list-group-item">
                      <i className="icon-map-marker1 mr-1"></i>
                      {item && item.location ? item.location : null}
                    </li>
                  </ul>

                  {/**
                 <div className="product-user d-flex align-items-center mt-4">
                 <img src="demos/crowdfunding/images/investors/1.jpg" alt="" />
                 <a href="demo-crowdfunding-single.html">By Jackson Pot </a>
                 </div>
                 */}
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))
    : "Şuan görüntülenebilecek proje yok.";

  let blog = blogs
    ? blogs.slice(0, blogLength).map((item, index) => (
        <div className="col-lg-4 col-sm-6 mb-4" key={index}>
          <Link to={`/blog/blog-detay/${item._id}`}>
            <div className="i-products">
              <div className="products-image">
                <a href={`/blog/blog-detay/${item._id}`}>
                  <img
                    src={item.media ? item.media.url : null}
                    alt={item.caption}
                  />
                </a>
              </div>
              <div className="products-desc">
                <h3 className="font-weight-bold">
                  <a href={`/blog/blog-detay/${item._id}`}>{item.caption}</a>
                </h3>
                <p>
                  {" "}
                  {item && item.description
                    ? ReactHtmlParser(
                        item.description
                          .slice(0, 150)
                          .replace("<p>", "")
                          .replace("</p>", "")
                          .substring(0, 100) + "..."
                      )
                    : ""}
                </p>
                <div className="clear" />
                <div className="products-hoverlays">
                  {/* <span className="products-location">
                  <i className="icon-map-marker1" />
                  Melbourne, Australia
                </span> */}
                  <ul className="list-group-flush my-3 mb-0">
                    <li className="list-group-item">
                      <strong>
                        {item != null
                          ? Moment.utc(item.createdDate).format("Do MMMM YYYY")
                          : null}{" "}
                      </strong>{" "}
                      tarihinde oluşturuldu
                    </li>
                    {/* <li className="list-group-item">
                    <strong>$12,32,000</strong> Pledged
                  </li>
                  <li className="list-group-item">
                    <strong>123</strong> Partcipants
                  </li>
                  <li className="list-group-item">
                    <strong>345</strong> Contributions
                  </li> */}
                  </ul>
                  {/* <div className="product-user d-flex align-items-center mt-4">
                  <img src="/assets/img/investors/1.jpg" alt="" />
                  <a href="demo-crowdfunding-single.html">By Jackson Pot </a>
                </div> */}
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))
    : "Bloglar yuklenemiyor";

  return (
    <section id="content">
      <div className="content-wrap p-0">
        <div className="section bg-transparent mb-0 pb-0 border-0">
          <div className="container bg-color-light border-0 rounded-lg p-4 p-md-5">
            <div className="row justify-content-between align-items-center bottommargin-sm">
              <div className="col-lg-7 col-sm-7">
                <div className="heading-block border-bottom-0 mb-3">
                  <img
                    src="/assets/img/fikrimiz-org-3.png"
                    alt="Fikrimiz Logo"
                    width="320"
                    style={{ margin: "-20px -6px" }}
                  />
                </div>
                <div className="text-muted mb-0">
                  {ReactHtmlParser(
                    props.pageContent
                      .find((x) => x.contentKey === "home.blue.subtitle")
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-5 mt-4 mt-sm-0">
                <div className="bg-white center px-5 py-3 border">
                  <div className="counter counter-large color font-weight-bold colorOrange">
                    <span
                      data-from={0}
                      data-to={parseInt(
                        props.pageContent
                          .find(
                            (x) => x.contentKey === "home.blue.right.idea.count"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "")
                      )}
                      data-refresh-interval={10}
                      data-speed={2000}
                    />
                  </div>
                  <div className="line my-2" />
                  <h5 className="font-weight-normal mb-1">
                    {ReactHtmlParser(
                      props.pageContent.find(
                        (x) => x.contentKey === "home.blue.right.title"
                      ).managementContent
                    )}
                  </h5>
                </div>
              </div>
            </div>
            <div className="clear" />
            <div className="row justify-content-around">
              <div className="col-lg-3 col-md-4 mt-5">
                <div className="feature-text">
                  <div className="fbox-text colorRed">1</div>
                  <h3 className="colorRed">
                    {ReactHtmlParser(
                      props.pageContent
                        .find((x) => x.contentKey === "home.blue.obj1.title")
                        .managementContent.replace("<p>", "")
                        .replace("</p>", "")
                    )}
                  </h3>
                </div>
                <p>
                  {ReactHtmlParser(
                    props.pageContent
                      .find((x) => x.contentKey === "home.blue.obj1.subtitle")
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")
                  )}
                </p>
              </div>
              <div className="col-lg-3 col-md-4 mt-5">
                <div className="feature-text">
                  <div className="fbox-text colorOrange">2</div>
                  <h3 className="colorOrange">
                    {ReactHtmlParser(
                      props.pageContent
                        .find((x) => x.contentKey === "home.blue.obj2.title")
                        .managementContent.replace("<p>", "")
                        .replace("</p>", "")
                    )}
                  </h3>
                </div>
                <p>
                  {ReactHtmlParser(
                    props.pageContent
                      .find((x) => x.contentKey === "home.blue.obj2.subtitle")
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")
                  )}
                </p>
              </div>
              <div className="col-lg-3 col-md-4 mt-5">
                <div className="feature-text">
                  <div className="fbox-text colorGreen">3</div>
                  <h3 className="colorGreen">
                    {ReactHtmlParser(
                      props.pageContent
                        .find((x) => x.contentKey === "home.blue.obj3.title")
                        .managementContent.replace("<p>", "")
                        .replace("</p>", "")
                    )}
                  </h3>
                </div>
                <p>
                  {ReactHtmlParser(
                    props.pageContent
                      .find((x) => x.contentKey === "home.blue.obj3.subtitle")
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/** Proje ve Blog Bölümü */}

        <div className="section border-0 bg-transparent mb-1">
          <div className="container">
            <div className="row justify-content-between align-items-end bottommargin">
              <div className="col-md-7">
                <div className="heading-block border-bottom-0 mb-3">
                  <h2>
                    {ReactHtmlParser(
                      props.pageContent
                        .find((x) => x.contentKey === "home.project.title")
                        .managementContent.replace("<p>", "")
                        .replace("</p>", "")
                    )}
                  </h2>
                </div>
                <p className="text-muted mb-0">
                  {ReactHtmlParser(
                    props.pageContent
                      .find((x) => x.contentKey === "home.project.subtitle")
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")
                  )}
                </p>
              </div>
            </div>

            {isLoading && project ? (
              <div className="row">{project}</div>
            ) : (
              <Loader
                style={{ textAlign: "center" }}
                type="ThreeDots"
                color="#31F4E8"
                height={50}
                width={50}
                timeout={3000}
              />
            )}
            {projectLength === 0 ? null : (
              <div className="mt-1 mb-6 text-center">
                <Link
                  to="/projeler"
                  className="button button-large font-weight-semibold button-rounded ls0 nott ml-0"
                >
                  Tüm Projeleri Görüntüle
                </Link>
              </div>
            )}

            <div className="divider"></div>
            {isLoading && blog ? (
              <div>
                <div className="row justify-content-between align-items-end bottommargin-sm">
                  <div className="col-md-7">
                    <div className="heading-block border-bottom-0 mb-3">
                      <h2>Blog</h2>
                    </div>
                  </div>
                </div>
                <div className="row">{blog}</div>
              </div>
            ) : (
              <Loader
                style={{ textAlign: "center" }}
                type="ThreeDots"
                color="#31F4E8"
                height={50}
                width={50}
                timeout={3000}
              />
            )}
            {blogLength === 0 ? null : (
              <div className="mt-3 mb-3 text-center">
                <Link
                  to="/bloglar"
                  className="button button-large font-weight-semibold button-rounded ls0 nott ml-0"
                >
                  Tüm Blogları Görüntüle
                </Link>
              </div>
            )}
          </div>
        </div>

        {/** Kazananlar */}

        <div className="section bg-transparent p-0 m-0">
          <div className="row no-gutters">
            <div className="col">
              <a data-toggle="tooltip" title="Duygu Yılmaz Hancılar">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/duyguyilmazhancilar.png"
                  alt="Duygu Yılmaz Hancılar"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Ferhat Öner">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/ferhatoner.png"
                  alt="Ferhat Öner"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Tayfun Demircan">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/tayfundemircan.png"
                  alt="Tayfun Demircan"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Kardelen Dağeri">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/kardelendegeri.png"
                  alt="Kardelen Dağeri"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Gizem Eryener">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/gizemeryener.png"
                  alt="Gizem Eryener"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Nihat Cibooğlu">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/nihatcibooglu.png"
                  alt="Nihat Cibooğlu"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Enes Gemeç">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/enesgemec.png"
                  alt="Enes Gemeç"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Emre Tanık">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/emretanik.png"
                  alt="Emre Tanık"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Cansu Demirkaynak">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/cansudemirkaynak.png"
                  alt="Cansu Demirkaynak"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Yunus Eskiocak">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/yunuseskiocak.png"
                  alt="Yunus Eskiocak"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Ayşegül Kaya">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/aysegulkaya.png"
                  alt="Ayşegül Kaya"
                />
              </a>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col">
              <a data-toggle="tooltip" title="Tuğba Hozer">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/tugbahozer.png"
                  alt="Tuğba Hozer"
                />
              </a>
            </div>
            <div className="col-md-10 d-md-flex justify-content-between align-items-center px-5 py-4 py-md-0">
              <div className="mb-3 mb-md-0">
                <h3 className="mb-0 color font-weight-bold">
                  {ReactHtmlParser(
                    props.pageContent
                      .find(
                        (x) => x.contentKey === "home.success.stories.title"
                      )
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")
                  )}
                </h3>
                <p className="mb-0 text-muted font-weight-normal">
                  {ReactHtmlParser(
                    props.pageContent
                      .find(
                        (x) => x.contentKey === "home.success.stories.subtitle"
                      )
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")
                  )}
                </p>
              </div>
              {loginModalStatus && <Login loginModal={setLoginModalStatus} />}
              {!ctx.isLoggedIn ? (
                <button
                  onClick={() => setLoginModalStatus(true)}
                  className="button button-large font-weight-semibold button-rounded ls0 nott m-0"
                >
                  {ReactHtmlParser(
                    props.pageContent
                      .find(
                        (x) =>
                          x.contentKey === "home.success.stories.button.title"
                      )
                      .managementContent.replace("<p>", "")
                      .replace("</p>", "")
                  )}
                </button>
              ) : null}
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Zeynep Yılmaztürk">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/zeynepyilmazturk.png"
                  alt="Zeynep Yılmaztürk"
                />
              </a>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col">
              <a data-toggle="tooltip" title="Nurcan Sağlam">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/nurcansaglam.png"
                  alt="Nurcan Sağlam"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Yunus Emre Yılmaz">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/yunusemreyilmaz.png"
                  alt="Yunus Emre Yılmaz"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Şenol Saygıner">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/senolsayginer.png"
                  alt="Şenol Saygıner"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Şeyma Genç">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/seymagenc.png"
                  alt="Şeyma Genç"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Ali Özenç">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/aliozenc.png"
                  alt="Ali Özenç"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Efe Karahan">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/efekarahan.png"
                  alt="Efe Karahan"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Özge Temiz Kurt">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/ozgetemizkurt.png"
                  alt="Özge Temiz Kurt"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Başak Sevmez">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/basaksevmez.png"
                  alt="Başak Sevmez"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Masum Raskılıç">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/masumraskilic.png"
                  alt="Masum Raskılıç"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Derya Elbeyli">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/deryaelbeyli.png"
                  alt="Derya Elbeyli"
                />
              </a>
            </div>
            <div className="col">
              <a data-toggle="tooltip" title="Zeynep Kandemir">
                <img
                  className="op-ts op-1 h-op-06"
                  src="/assets/img/kazananlar/zeynepkandemir.png"
                  alt="Zeynep Kandemir"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;

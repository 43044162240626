export default function validate(values) {
  let errors = {};
  if (!values.caption) {
    errors.caption = 'Başlık alanı boş geçilmemelidir';
  }
  if (values.caption.length > 40 ) {
    errors.caption = 'Başlık alanı 40 karakterden fazla olmamalıdır';
  }
  if (!values.idea) {
    errors.idea = 'Fikir alanı boş geçilmemelidir';
  }
  if (values.idea.length > 600 ) {
    errors.idea = 'Fikir alanı 600 karakterden fazla olmamalıdır';
  }
  return errors;
};
// contact.js
import React, { useState, Component, useEffect, useContext } from "react";
import ReactHtmlParser from "react-html-parser";

/** api part */
import * as constValues from "../../utilities/constants";
import axios from "axios";
import AuthContext from "../../store/authcontext";

/** parts */
import ContactForm from "../../components/forms/contactForm";

import { Helmet } from "react-helmet";

const ContactPage = (props) => {
  const [pageContent, setPageContent] = useState([]);
  const [isPageContentLoading, setIsPageContentLoading] = useState(false);
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const existingScript = document.getElementById("fscript");
    if (existingScript) {
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = "fscript";
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }
  }, []);

  /** Load Page Content */
  useEffect(() => {
    const getAllContents = () => {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (
        (ctx.isLoggedIn && userToken !== null) ||
        (!ctx.isLoggedIn && anonymousToken !== null)
      ) {
        axios
          .post(
            constValues.API_URL + constValues.API_GET_SITEMANAGEMENT_VALUES,
            {
              ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
              ReqData: constValues.PAGE_GROUP_NAME_ILETISIM,
            }
          )
          .then((response) => {
            setPageContent(response.data.ResData);
            setIsPageContentLoading(true);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getAllContents();
  }, [ctx.isLoggedIn]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fikrimiz - İletişim</title>
        <meta name="description" content="Sosyal Yaratıcılık Başlıyor" />
        <meta
          name="keywords"
          content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
        />
        <meta property="og:image" content="/assets/img/fikrimiz-org-3.png" />
      </Helmet>
      <section id="content">
        <div className="content-wrap pt-0">
          <div className="section mt-0 overflow-visible">
            <div className="container">
              <div className="row justify-content-center center">
                <div className="col-lg-7">
                  <div className="col-auto">
                    <div className="nott ls0 h2 font-weight-bold">
                      <h1>
                        {isPageContentLoading
                          ? ReactHtmlParser(
                              pageContent
                                .find((x) => x.contentKey === "contact.title")
                                .managementContent.replace("<p>", "")
                                .replace("</p>", "")
                            )
                          : null}
                      </h1>
                    </div>
                    <p className="text-muted mb-1">
                      {isPageContentLoading
                        ? ReactHtmlParser(
                            pageContent
                              .find((x) => x.contentKey === "contact.subtitle")
                              .managementContent.replace("<p>", "")
                              .replace("</p>", "")
                          )
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrap">
            <div className="container clearfix">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;

// blogs.js
import React, { useState, Component, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  useParams,
  useLocation,
} from "react-router-dom";
import Moment from "moment";
import 'moment/locale/tr';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
/** api part */
import * as constValues from "../../utilities/constants";
import axios from "axios";

import AuthContext from "../../store/authcontext";

const Blog = (props) => {
  const ctx = useContext(AuthContext);
  const [pageContent, setPageContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const location = window.location.href;

  function detectMob() {
    return ((window.innerWidth <= 1080) /*&& ( window.innerHeight <= 600 )*/);
  }

  /** get blogId id */
  let { blogId } = useParams();
  const getAllContents = () => {
    axios
      .post(constValues.API_URL + constValues.GET_BLOG, {
        ReqToken: ctx.isLoggedIn
          ? localStorage.getItem("REACT_TOKEN_USER")
          : localStorage.getItem("REACT_TOKEN_ANONYMOUS"),
        ReqData: blogId,
      })
      .then((response) => {
        setPageContent(response.data.ResData);
        setIsLoading(true);
      })
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    getAllContents();
    setIsMobile(detectMob());
  }, [ctx.isLoggedIn]);

  useEffect(()=>{
    const existingScript = document.getElementById('fscript');
    if(existingScript){
      existingScript.remove();
      const tag = document.createElement("script");
      tag.src = tag;
      tag.id = 'fscript';
      tag.src = "/assets/js/functions.js?v=1.1";
      tag.async = true;
      document.body.appendChild(tag);
    }

  });

  const shareThis = (type, title) => {
    switch (type) {
      case "twitter":
        window.shareOnTwitter();
        break;
      case "facebook":
        window.shareOnFacebook();
        break;
        case "whatsapp":
          if(isMobile){
              window.open('whatsapp://send?text=Blog yazısını okuyabilirsiniz:'+location);
          }else{
              window.open('https://web.whatsapp.com/send?text=Blog yazısını okuyabilirsiniz: '+location, '_blank');
          }
        break;
      default:
        break;
    }
  };

  /** Current Url */
  const currentURL = window.location.href;

  return (
    <div>
      {isLoading ? <div>
        <Helmet>
        <meta charSet="utf-8" />
        <title>
          {isLoading && pageContent && pageContent.caption
            ? `Fikrimiz Blog - ${ pageContent.caption}`
            : null}
        </title>
        <meta name="description" content={isLoading && pageContent ?pageContent.description : null} />
        <meta
          name="keywords"
          content="Fikrimiz, Yaratıcılık, Yarışma, Sosyal, Reklam, Blog"
        />
        <meta
          property="og:image"
          content={
            isLoading && pageContent && pageContent.media
              ? pageContent.media.url
              : null
          }
        />
        <meta property="og:url" content={currentURL}></meta>
      </Helmet>
 
      <section id="page-title">
        <div className="container clearfix">
          <h1>Bloglar</h1>
        </div>
      </section>
      <section id="content">
        <div className="content-wrap">
          <div className="container clearfix">
            <div className="row gutter-40 col-mb-80">
              <div className="postcontent col-lg-9">
                <div className="single-post mb-0">
                  <div className="entry clearfix">
                    <div className="entry-title">
                      <h2>{isLoading && pageContent ? pageContent.caption : null}</h2>
                    </div>

                    <div className="entry-meta">
                      <ul>
                        <li>
                          <i className="icon-calendar3" />
                          {Moment.utc(isLoading && pageContent ? pageContent.updatedDate : null).format("Do MMMM YYYY")}
                        </li>
                      </ul>
                    </div>

                    <div className="entry-image">
                      <a href="#">
                        <img
                          src={
                            isLoading && pageContent  && pageContent.media
                              ? pageContent.media.url
                              : null
                          }
                          alt={isLoading && pageContent ? pageContent.caption : null}
                        />
                      </a>
                    </div>
                    <div className="entry-content mt-0">
                      {ReactHtmlParser(
                        isLoading && pageContent && pageContent.description
                          ? pageContent.description
                          : null
                      )}

                      <div className="clear" />

                      <div className="si-share border-0 d-flex justify-content-between align-items-center">
                        <span>Paylaşın:</span>
                        <div>
                          <a
                            onClick={() => {
                              shareThis("facebook", "");
                            }}
                            className="social-icon si-borderless si-facebook"
                          >
                            <i className="icon-facebook" />
                            <i className="icon-facebook" />
                          </a>
                          <a
                            onClick={() => {
                              shareThis("twitter", "");
                            }}
                            className="social-icon si-borderless si-twitter"
                          >
                            <i className="icon-twitter" />
                            <i className="icon-twitter" />
                          </a>
                          <a
                            onClick={() => {
                              shareThis("whatsapp", "");
                            }}
                            className="social-icon si-borderless si-whatsapp"
                          >
                            <i className="icon-whatsapp" />
                            <i className="icon-whatsapp" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>:
      <Loader style={{ textAlign: "center" }} type="ThreeDots" color="#31F4E8" height={100}width={100} />
      }

    </div>
  );
};

export default Blog;

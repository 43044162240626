// detailHeader.js
import React, { useState, Component, useEffect } from 'react';
import { useLocation } from 'react-router-dom'



const DetailHeader = props => {
    const [pageContent, setPageContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const location = window.location.href;

    function detectMob() {
        return ( ( window.innerWidth <= 1080 ) /*&& ( window.innerHeight <= 600 )*/ );
      }

    const shareThis = (type, title) => {
        switch(type){
            case "twitter":
                window.shareOnTwitter();
                break;
            case "facebook":
                window.shareOnFacebook();
                break;
            case "whatsapp":
                if(isMobile){
                    window.open('whatsapp://send?text=Çok Güzel Proje bak :'+location);
                }else{
                    window.open('https://web.whatsapp.com/send?text=Çok Güzel Proje bak : '+location, '_blank');
                }
                
            default:
                break;
        }
    }

    useEffect(() => {
        setIsMobile(detectMob());
    }, []);

    return (
        <div className="row">
            <div className="col-lg-8">
                <div className="row justify-content-between align-items-end">
                    <div className="col-auto order-md-last mb-4 mb-md-0">
                        <a href="#" id="notifylink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="0,12"><i data-toggle="tooltip" data-placement="top" title="Paylaş" className="icon-line-share btn btn-secondary" /></a>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="notifylink">
                            <a className="dropdown-item" onClick={() => { shareThis('whatsapp', ''); }}><i className="icon-whatsapp mr-2" />Whatsapp</a>
                            <a className="dropdown-item" onClick={() => { shareThis('facebook', ''); }}><i className="icon-facebook mr-2" />Facebook</a>
                            <a className="dropdown-item" onClick={() => { shareThis('twitter', ''); }}><i className="icon-twitter mr-2" />Twitter</a>
                        </div>
                    </div>
                    <div className="col-auto">
                        {/* Title
									============================================= */}
                        <h2 className="nott ls0 h2 font-weight-bold">{props.projectTitle}</h2>
                       {/* <p className="text-muted mb-1">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                        {/* Tag Cloud
									============================================= */}
                                    {/*
                        <div className="tagcloud my-3 clearfix">
                            <a href="#">Detaylar</a>
                            <a href="#">Görseller</a>
                            <a href="#">Popular</a>
                            <a href="#">Most Loved</a>
                        </div> */}{/* .tagcloud end */}
                        <div className="clear" />
                        {/*<i className="icon-map-marker1" /> <u>Melbourne, Australia</u>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DetailHeader;
// projectContent.js
import React, { useState } from "react";
import Loader from "react-loader-spinner";

/**Parts */
import ProjectItem from "../parts/projectItem";
import Pagination from "../../layouts/Pagination";

const ProjectContent = (props) => {
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [projectPerPage, setProjectPerPage] = useState(6);

  // get current projects
  const indexOfLastProject = currentPage * projectPerPage;
  const indexOfFirstProject = indexOfLastProject - projectPerPage;

  const currentProjects = props.projectList
    ? props.projectList.slice(indexOfFirstProject, indexOfLastProject)
    : null;
  // change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container">
      {props.projectList ? (
        <div className="row">
          {currentProjects
            ? currentProjects.map((project, i) => {
                return (
                  <ProjectItem
                    project={project}
                    i={i}
                    setProjectList={props.setProjectList}
                    projectList={props.projectList}
                  />
                );
              })
            : null}
        </div>
      ) : (
        <Loader
          style={{ textAlign: "center" }}
          type="ThreeDots"
          color="#31F4E8"
          height={75}
          width={75}
          timeout={10000}
        />
      )}

      <Pagination
        postsPerPage={projectPerPage}
        totalPosts={props.projectList ? props.projectList.length : 0}
        paginate={paginate}
        pageNumber={currentPage}
      />
    </div>
  );
};
export default ProjectContent;

import React, { useState, useEffect } from "react";

const AuthContext = React.createContext({
  isLoggedIn: false,
  onLogout: () => {},
  onLogin: (responseData) => {},
  userInfo: null,
  isNotificationRead: false,
  setIsNotificationRead: () => {},
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isNotificationRead, setIsNotificationRead] = useState(false);

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn");

    if (storedUserLoggedInInformation === "1") {
      const storedUserInfo = localStorage.getItem("USER_INFO");
      setIsLoggedIn(true);
      setUserInfo(JSON.parse(storedUserInfo));
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("REACT_TOKEN_USER");
    localStorage.removeItem("USER_INFO");
    setIsLoggedIn(false);
  };

  const loginHandler = (responseData) => {
    localStorage.setItem("isLoggedIn", "1");
    setUserInfo(responseData);
    localStorage.setItem("USER_INFO", JSON.stringify(responseData));
    localStorage.setItem("REACT_TOKEN_USER", responseData.token);
    localStorage.removeItem("REACT_TOKEN_ANONYMOUS");
    setIsLoggedIn(true);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        userInfo: userInfo,
        isNotificationRead: isNotificationRead,
        setIsNotificationRead: setIsNotificationRead,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

// projectHeader.js
import React, { useState, Component, useEffect,useContext } from 'react';
/** api part */
import * as constValues from "../../../utilities/constants";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import AuthContext from "../../../store/authcontext";


const ProjectHeader = props => {
    const ctx = useContext(AuthContext);
    const [pageContent, setPageContent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageContentLoading, setIsPageContentLoading] = useState(false);

    useEffect(() => {

    }, []);

    /** Load Page Content */
    useEffect(() => {
        const getAllContents = () => {
            const userToken = localStorage.getItem("REACT_TOKEN_USER");
            const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
            if (
                (ctx.isLoggedIn && userToken !== null) ||
                (!ctx.isLoggedIn && anonymousToken !== null)
            ) {
                axios
                    .post(
                        constValues.API_URL + constValues.API_GET_SITEMANAGEMENT_VALUES,
                        {
                            ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
                            ReqData: constValues.PAGE_GROUP_NAME_PROJELER,
                        }
                    )
                    .then((response) => {
                        setPageContent(response.data.ResData);
                        setIsPageContentLoading(true);
                    })
                    .catch((error) => console.error("Error", error));
            }
        };
        getAllContents();
    }, [ctx.isLoggedIn]);

    /*
    useEffect(()=>{
        const existingScript = document.getElementById('fscript');
        if(existingScript){
          existingScript.remove();
          const tag = document.createElement("script");
          tag.src = tag;
          tag.id = 'fscript';
          tag.src = "/assets/js/functions.js?v=1.1";
          tag.async = true;
          document.body.appendChild(tag);
        }
    
      });
*/
    return (
        <div className="section mt-0 overflow-visible">
            <div className="container">
                <div className="row justify-content-center center">
                    <div className="col-lg-7">
                        <div className="heading-block border-bottom-0 mb-4">
                            <h2 className="mb-3">{isPageContentLoading ? pageContent
                          .find(
                            (x) => x.contentKey === "projeler.title"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "") : null}</h2>
                            <p className="text-muted mb-0">{isPageContentLoading ? pageContent
                          .find(
                            (x) => x.contentKey === "projeler.subtitle"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "") : null}</p>
                        </div>
                        {/** 
                        <div className="input-group input-group-lg mb-4">
                            <input type="text" className="form-control" aria-label="Text input with dropdown button" placeholder="Arama.." />
                            <select className="custom-select col-4">
                                <option selected value="All">All</option>
                                <option value="Business">Business</option>
                                <option value="Design">Design</option>
                                <option value="Tech">Tech</option>
                                <option value="Fashion">Fashion</option>
                                <option value="Music">Music</option>
                                <option value="Software">Software</option>
                                <option value="Hardware">Hardware</option>
                            </select>
                            <div className="input-group-append">
                                <button className="btn bg-color text-white border-0" type="button"><i className="icon-search" /></button>
                            </div>
                        </div>
                        <a className="button button-rounded" href="#" role="button">Popular</a>
                        <a className="button button-rounded button-dark button-black" href="#" role="button">Newest</a>
                        */}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ProjectHeader;
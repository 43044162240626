// Footer.js
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

//connections
import * as constValues from "../../utilities/constants";
import axios from "axios";
import AuthContext from "../../store/authcontext";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const Footer = (props) => {
  const [footerContent, setFooterContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const getFooterContents = () => {
      const userToken = localStorage.getItem("REACT_TOKEN_USER");
      const anonymousToken = localStorage.getItem("REACT_TOKEN_ANONYMOUS");
      if (
        (ctx.isLoggedIn && userToken !== null) ||
        (!ctx.isLoggedIn && anonymousToken !== null)
      ) {
        axios
          .post(
            constValues.API_URL + constValues.API_GET_SITEMANAGEMENT_VALUES,
            {
              ReqToken: ctx.isLoggedIn ? userToken : anonymousToken,
              ReqData: constValues.PAGE_GROUP_NAME_ANASAYFA,
            }
          )
          .then((response) => {
            setFooterContent(response.data.ResData);
            setIsLoading(true);
          })
          .catch((error) => console.error("Error", error));
      }
    };
    getFooterContents();
  }, [ctx.isLoggedIn]);

  return (
    <footer
      id="footer"
      className="dark font-weight-semibold"
      style={{ backgroundColor: "#e9a24b", color: "#fffffe" }}
    >
      <div className="container">
        <div className="footer-widgets-wrap pb-4">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-6">
              <div className="widget">
                <h4 className="ls0 mb-4 nott">
                  {isLoading
                    ? ReactHtmlParser(
                        footerContent
                          .find(
                            (x) =>
                              x.contentKey === "home.footer.left.column.text"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "")
                      )
                    : null}
                </h4>
                <ul
                  className="list-unstyled iconlist ml-0"
                  style={{ color: "#fffffe" }}
                >
                  {/** 
                  <li className="mb-2">
                    <a href="/fikrimiz-nedir">Fikrimiz Nedir?</a>
                  </li>
                  */}
                  <li className="mb-2">
                    <Link to="/projeler">Projeler</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/bloglar">Bloglar</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/liderler">Liderler</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="/iletisim">İletişim</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-6">
              <div className="widget">
                <h4 className="ls0 mb-4 nott">
                  {isLoading
                    ? ReactHtmlParser(
                        footerContent
                          .find(
                            (x) =>
                              x.contentKey === "home.footer.right.column.text"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "")
                      )
                    : null}
                </h4>
                <ul className="list-unstyled iconlist ml-0">
                  <li className="mb-2">
                    <a
                      href="http://www.gelecegipaylas.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Geleceği Paylaş
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="https://videa.com.tr/hakkimizda.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Videa Hakkında
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="http://videa.com.tr/kurallar.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Kullanım Sözleşmesi
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="https://videa.com.tr/altyapi.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Gizlilik ve Güvenlik
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 col-md-4 text-md-right">
              <div className="widget">
                <img
                  src="asssets/img/logo-footer.png"
                  alt=""
                  height={60}
                  className="mb-3"
                />
                <p>
                  {isLoading
                    ? ReactHtmlParser(
                        footerContent
                          .find(
                            (x) =>
                              x.contentKey === "home.footer.social.media.text"
                          )
                          .managementContent.replace("<p>", "")
                          .replace("</p>", "")
                      )
                    : null}
                </p>
                <div className="d-flex justify-content-md-end">
                  <a
                    href={
                      isLoading
                        ? ReactHtmlParser(
                            footerContent
                              .find(
                                (x) =>
                                  x.contentKey === "home.footer.facebook.link"
                              )
                              .managementContent.replace("<p>", "")
                              .replace("</p>", "")
                          )
                        : "#"
                    }
                    className="social-icon si-small si-facebook si-colored"
                    title="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-facebook" />
                    <i className="icon-facebook" />
                  </a>
                  <a
                    href={
                      isLoading
                        ? ReactHtmlParser(
                            footerContent
                              .find(
                                (x) =>
                                  x.contentKey === "home.footer.twitter.link"
                              )
                              .managementContent.replace("<p>", "")
                              .replace("</p>", "")
                          )
                        : "#"
                    }
                    className="social-icon si-small si-twitter si-colored"
                    title="Twitter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-line-twitter" />
                    <i className="icon-line-twitter" />
                  </a>
                  <a
                    href={
                      isLoading
                        ? ReactHtmlParser(
                            footerContent
                              .find(
                                (x) =>
                                  x.contentKey === "home.footer.instagram.link"
                              )
                              .managementContent.replace("<p>", "")
                              .replace("</p>", "")
                          )
                        : "#"
                    }
                    className="social-icon si-small si-instagram si-colored"
                    title="instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-instagram" />
                    <i className="icon-instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {/** 
                <div className="subscribe-widget d-flex align-items-center col justify-content-center py-2" data-loader="button">
                  <h4 className="mr-3 mb-0">Refer a startup, get 1,500</h4>
                  <div className="widget-subscribe-form-result" />
                  <form id="widget-subscribe-form" action="include/subscribe.php" method="post" className="mb-0">
                    <div className="input-group">
                      <input type="email" name="widget-subscribe-form-email" className="form-control required not-dark" placeholder="Enter Your Email Address.." />
                      <div className="input-group-append">
                        <button type="submit" className="btn button font-weight-semibold ls0 nott m-0">Subscribe</button>
                      </div>
                    </div>
                  </form>
                </div>
                <hr />
                */}
          <p className="font-weight-light mt-4" style={{ color: "#fffffe" }}>
            {isLoading
              ? ReactHtmlParser(
                  footerContent
                    .find((x) => x.contentKey === "home.footer.description")
                    .managementContent.replace("<p>", "")
                    .replace("</p>", "")
                )
              : "#"}
          </p>
        </div>
      </div>
      <div id="copyrights" style={{ color: "#eeeeee" }}>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              © {new Date().getFullYear()} Videa Bilişim Hizmetleri Anonim
              Şirketi
            </div>
            {/**
                  <div className="col-md-6 d-md-flex flex-md-column align-items-md-end mt-4 mt-md-0">
                    <div className="copyrights-menu copyright-links">
                      <a href="#">About</a>/<a href="#">Features</a>/<a href="#">FAQs</a>/<a href="#">Contact</a>
                    </div>
                  </div>
                   */}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
